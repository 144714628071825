import { IGetProjectResponse } from '../../../models/projectModel';
import { ProjectTypesEnum } from '../../create-project/helpers';

function categoryFilter(
	rawCategories: string[],
	createProjectV2: boolean,
	project: IGetProjectResponse | null,
) {
	if (!createProjectV2) return rawCategories;

	if (project?.type === ProjectTypesEnum.CM) {
		return rawCategories.filter((category) => category !== 'RenderingHost');
	}

	if (project?.type === ProjectTypesEnum.EH) {
		return rawCategories.filter((category) => category === 'RenderingHost');
	}

	return rawCategories;
}

export { categoryFilter };
