import { useCreateRepositoryMutation } from '../../../app/services/repositories';
import { useCreateProject } from '../context/CreateProjectContext';
import { ResponseStatusEnum, ResponseStatusTypes } from '../../utils/helpers';
import { IRepositoryTemplateDetails } from '../../../app/models/repositoriesModel';
import { useState } from 'react';
import { useGetGitHubBranches } from '../../../app/services/githubApi';
import {
	getProcessStatus,
	isEHProjectType,
	ProjectTypesEnum,
	TDeployOnCommit,
	TEnvironmentName,
	TProjectName,
	TRepository,
	TRepositoryBranch,
	TRepositoryId,
	TRepositoryRelativePath,
} from '../../../app/pages/create-project/helpers';
import { useCreateProjectMutationV2 } from '../../../app/services/projects';
import { useEnvironmentCreation } from '..';

const { ERROR, SUCCESS } = ResponseStatusEnum;

export const useXMCloudTemplateGitHubProjectCreation = ({
	projectNameSpace = 'name',
	repositoryNamespace = 'repository',
	repositoryIdNamespace = 'repositoryId',
	repositoryBranchNamespace = 'repositoryBranch',
	repositoryRelativePathNamespace = 'repositoryRelativePath',
	environmentNamespace = 'environmentName',
	deployOnCommitNamespace = 'deployOnCommit',
	XMCloudTemplate,
	projectType,
	token,
	shouldGotoDeploymentPage = false,
	shouldGotoDeploymentsPage = false,
	onCmProjectCreationSuccess,
	skipCreateRepository = false,
	repositoryBranch = '',
	cmCurrentEnvironmentId,
	templateRepository,
}: {
	token: string;
	XMCloudTemplate: IRepositoryTemplateDetails;
	projectType: ProjectTypesEnum;
	repositoryNamespace?: TRepository;
	repositoryIdNamespace?: TRepositoryId;
	repositoryBranchNamespace?: TRepositoryBranch;
	repositoryRelativePathNamespace?: TRepositoryRelativePath;
	environmentNamespace?: TEnvironmentName;
	projectNameSpace?: TProjectName;
	deployOnCommitNamespace?: TDeployOnCommit;
	shouldGotoDeploymentPage?: boolean;
	shouldGotoDeploymentsPage?: boolean;
	onCmProjectCreationSuccess?: (id: string) => void;
	skipCreateRepository?: boolean;
	repositoryBranch?: string;
	cmCurrentEnvironmentId?: string;
	templateRepository: string;
}) => {
	const { values, isOwnCode, setFieldValue } = useCreateProject();
	const sourceControlIntegrationId = values.sourceControlIntegrationId;
	const [projectID, setprojectID] = useState('');

	const [repositoryDetails, setRepositoryDetails] = useState({
		[repositoryBranchNamespace]: '',
		[repositoryNamespace]: '',
		[repositoryIdNamespace]: '',
		owner: '',
	});

	const {
		mutate: createGithubRepo,
		isLoading: isCreateGithubRepoLoading,
		status: createGithubRepoStatus,
	} = useCreateRepositoryMutation();

	const {
		loading: isRepositoryLoading,
		branchStatus,
		refetch: refetchGitHubBranches,
	} = useGetGitHubBranches({
		token,
		owner: repositoryDetails.owner,
		repo: values[repositoryNamespace],
		onSuccess: onGitHubBranchesSuccess,
		repositoryNamespace,
	});

	const {
		mutate: createProject,
		isLoading: isLoadingCreateProject,
		status: createProjectStatus,
	} = useCreateProjectMutationV2();

	function onGitHubBranchesSuccess() {
		onSecondProcess();
	}

	const {
		onEnvironmentCreation,
		onEditEnvironment,
		onDeploymentCreation,
		onDeploymentDeploy,
		isLoading: isLoadingCreateEnvironment,
		shouldReTryEnvironmentCreation,
		shouldReTryDeploymentCreation,
		shouldReTryDeploymentDeploy,
		currentEnvironmentId,
		currentDeploymentId,
		createEnvironmentStatus,
		createDeploymentStatus,
		deploymentDeployStatus,
		editEnvironmentStatus,
		shouldReTryEditEnvironment,
	} = useEnvironmentCreation({
		shouldGotoDeploymentPage,
		onCmProjectCreationSuccess,
		shouldGotoDeploymentsPage,
		shouldEditEnvironment: isOwnCode,
	});
	const editEnvStatus = isOwnCode ? editEnvironmentStatus : SUCCESS;

	const shouldRetryFirstProcess = createGithubRepoStatus === ERROR;
	const shouldRetrySecondProcess = createProjectStatus === ERROR;
	const shouldRetryThirdProcess = shouldReTryEnvironmentCreation;
	const shouldRetryFourthProcess = shouldReTryDeploymentCreation;
	const shouldRetryFifthProcess = shouldReTryDeploymentDeploy;
	const shouldEditEnvironmentProcess = shouldReTryEditEnvironment;

	const isEH = isEHProjectType(projectType);

	function onFirstProcess() {
		const payload = {
			integrationId: sourceControlIntegrationId,
			owner: values.connectionName,
			repositoryName: values[repositoryNamespace],
			templateRepository,
			templateOwner: XMCloudTemplate?.owner || '',
		};
		createGithubRepo(payload, {
			onSuccess: (data) => {
				const repositoryName = data.data.name;
				const repositoryBranch = data.data.defaultBranch;
				const repositoryId = data.data.id;
				const owner = data.data.owner;
				setRepositoryDetails({
					[repositoryBranchNamespace]: repositoryBranch,
					[repositoryNamespace]: repositoryName,
					[repositoryIdNamespace]: repositoryId,
					owner,
				});
				setFieldValue(repositoryBranchNamespace, repositoryBranch);
				setFieldValue(repositoryIdNamespace, repositoryId);
				setTimeout(() => {
					refetchGitHubBranches();
				}, 300);
			},
		});
	}

	function onSecondProcess(cmEnvId?: string) {
		const projectPayload = {
			name: values[projectNameSpace],
			repository: values[repositoryNamespace],
			sourceControlIntegrationId,
			repositoryId: `${values[repositoryIdNamespace]}`,
			type: projectType,
			editingHostProjectDetails: {
				repositoryRelativePath: values[repositoryRelativePathNamespace],
			},
		};
		createProject(
			{ project: projectPayload },
			{
				onSuccess: (data) => {
					const currentProjectId = data.data.id;
					setprojectID(currentProjectId);
					onThirdProcess(currentProjectId, cmEnvId);
				},
			},
		);
	}

	const onThirdProcess = (projectId: string, cmEnvId?: string) => {
		const envPayload = {
			name: values[environmentNamespace],
			tenantType: values.tenantType,
			repositoryBranch:
				values[repositoryBranchNamespace] || repositoryBranch,
			deployOnCommit: values[deployOnCommitNamespace],
			linkEnvironmentToDefaultBranch:
				values.linkEnvironmentToDefaultBranch,
			...(isEH && {
				editingHostEnvironmentDetails: {
					cmEnvironmentId:
						cmEnvId ||
						values.cmEnvironmentId ||
						cmCurrentEnvironmentId,
				},
			}),
		};
		onEnvironmentCreation(projectId, envPayload);
	};

	function onHandleCreationProcess(currentCmId?: string) {
		const cmEnvId = typeof currentCmId === 'string' ? currentCmId : '';
		if (shouldRetryFirstProcess) {
			onFirstProcess();
		} else if (shouldRetrySecondProcess) {
			onSecondProcess();
		} else if (shouldRetryThirdProcess) {
			onThirdProcess(projectID);
		} else if (shouldEditEnvironmentProcess) {
			// edit environment it is only for bring your own code
			onEditEnvironment(
				repositoryBranch,
				projectID,
				currentEnvironmentId,
			);
		} else if (shouldRetryFourthProcess) {
			onDeploymentCreation(projectID, currentEnvironmentId);
		} else if (shouldRetryFifthProcess) {
			onDeploymentDeploy(
				projectID,
				currentEnvironmentId,
				currentDeploymentId,
			);
		} else {
			if (skipCreateRepository) {
				setFieldValue('cmEnvironmentId', cmEnvId);
				onSecondProcess(cmEnvId);
				return;
			}
			onFirstProcess();
		}
	}

	const repositoryCreationStatus = getProcessStatus(
		createGithubRepoStatus,
		branchStatus as ResponseStatusTypes,
	);
	const projectCreationStatus = getProcessStatus(createProjectStatus);
	const environmentCreationStatus = getProcessStatus(
		createEnvironmentStatus,
		editEnvStatus,
	);
	const deploymentCreationStatus = getProcessStatus(
		createDeploymentStatus,
		deploymentDeployStatus,
	);

	const isLoading =
		isCreateGithubRepoLoading ||
		isRepositoryLoading ||
		isLoadingCreateProject ||
		isLoadingCreateEnvironment;
	const shouldRetry =
		shouldRetryFirstProcess ||
		shouldRetrySecondProcess ||
		shouldRetryThirdProcess ||
		shouldRetryFourthProcess ||
		shouldRetryFifthProcess;

	return {
		isLoading,
		onHandleCreationProcess,
		repositoryCreationStatus,
		projectCreationStatus,
		environmentCreationStatus,
		deploymentCreationStatus,
		shouldRetry,
		currentEnvironmentId,
		repositoryDetails,
		isOwnCode,
	};
};
