import { Stack, Tab, TabList, Wrap } from '@chakra-ui/react';
import {
	createCombinedProject,
	secondStep,
	thirdStep,
} from '../../../../@xmcloud/core/messages/en';
import {
	useCreateProject,
	useSetupRepoValidation,
} from '../../../../@xmcloud/hooks';
import { ESourceControl } from '../../../../@xmcloud/types';
import { foundationTemplateLearnMoreUrl } from '../../../../@xmcloud/utils/constants';
import { useGetSourceControlAccessToken } from '../../../services/accessToken';
import { useValidateSourceControlIntegrationV2 } from '../../../services/repositories';
import { HeadingLg, ToggleStack } from '../../../shared-components';
import {
	AdoRepository,
	DualCardItem,
	ExistingConnection,
	FooterBtnGroups,
	GithubRepository,
	Microcopy,
} from '../components';
import {
	ADO_ORGANIZATION,
	ADO_PROJECT_NAME,
	CM_ADO_ORGANIZATION,
	CM_ADO_PROJECT_NAME,
	CM_REPO_BRANCH,
	CM_REPOSITORY,
	CM_REPOSITORY_ID,
	EH_ADO_ORGANIZATION,
	EH_ADO_PROJECT_NAME,
	EH_REPO_BRANCH,
	EH_REPOSITORY,
	EH_REPOSITORY_ID,
	ExtractGoTo,
	goToCreateProjectPaths,
	IS_MONO_REPO,
	IS_USE_OWN_CODE,
	LINK_ENV_TO_DEFAULT_BRANCH,
	REPO_BRANCH,
	REPOSITORY,
	REPOSITORY_ID,
} from '../helpers';

const { ADO, GitHub } = ESourceControl;

const { combined, owncode } = createCombinedProject;
const { title, title2, usetemplate, useOwnCode } = secondStep;
const { monoRepo, separateRepo } = thirdStep;

const repoLookup = {
	[ADO]: (props: any) => <AdoRepository {...props} />,
	[GitHub]: (props: any) => <GithubRepository {...props} />,
	'': () => <></>,
};

const SetupRepository: React.FC = () => {
	const { values, isDualJourney, setValues, isMonoRepo, isOwnCode, prev } =
		useCreateProject();

	const sourceControlIntegrationId = values.sourceControlIntegrationId;

	const {
		isFetching: isSourceControlIntegrationLoading,
		isIntegrationIdValidationValid,
		integrationValidationErrorMsg,
	} = useValidateSourceControlIntegrationV2({
		integrationId: sourceControlIntegrationId,
		enabled: !!sourceControlIntegrationId,
	});
	const shouldCallAccessTokenApi =
		isIntegrationIdValidationValid && !isSourceControlIntegrationLoading;

	const { data: accessTokenData, isLoading: isLoadingAccessToken } =
		useGetSourceControlAccessToken(
			sourceControlIntegrationId,
			shouldCallAccessTokenApi,
		);
	const accessToken = accessTokenData?.data.accessToken || '';

	const { handleNext, isLoading: validationIsLoading } =
		useSetupRepoValidation({
			isIntegrationIdValidationValid,
			integrationValidationErrorMsg,
		});

	const CurrentRepoField = repoLookup[values.provider];

	const pageTitle = isOwnCode ? title2 : title;

	const handlePrev = () => {
		prev(goToCreateProjectPaths[values.journey] as ExtractGoTo);
	};

	const isLoading =
		isSourceControlIntegrationLoading ||
		validationIsLoading ||
		isLoadingAccessToken;

	return (
		<Stack gap="7" pb="12">
			<HeadingLg text={pageTitle} />
			<Stack spacing={0}>
				<ToggleStack defaultIndex={isOwnCode ? 1 : 0}>
					<TabList>
						<Tab
							onClick={() => {
								setValues((prev) => ({
									...prev,
									[IS_USE_OWN_CODE]: false,
									[LINK_ENV_TO_DEFAULT_BRANCH]: true,
									[REPOSITORY]: '',
									[CM_REPOSITORY]: '',
									[EH_REPOSITORY]: '',
								}));
							}}
							data-testid="use-xmcloud-template-btn"
						>
							{usetemplate}
						</Tab>
						<Tab
							onClick={() => {
								setValues((prev) => ({
									...prev,
									[IS_USE_OWN_CODE]: true,
									[LINK_ENV_TO_DEFAULT_BRANCH]: false,
									[REPOSITORY]: '',
									[CM_REPOSITORY]: '',
									[EH_REPOSITORY]: '',
								}));
							}}
							data-testid="use-own-code-btn"
						>
							{useOwnCode}
						</Tab>
					</TabList>
				</ToggleStack>
				<Microcopy
					text={isOwnCode ? owncode : combined}
					href={foundationTemplateLearnMoreUrl}
				/>
			</Stack>
			{isDualJourney && (
				<Stack spacing={0}>
					<ToggleStack defaultIndex={isMonoRepo ? 0 : 1}>
						<TabList>
							<Tab
								onClick={() => {
									setValues((prev) => ({
										...prev,
										[IS_MONO_REPO]: true,
									}));
								}}
								data-testid="monorepo-toggle-btn"
							>
								{monoRepo}
							</Tab>
							<Tab
								onClick={() => {
									setValues((prev) => ({
										...prev,
										[IS_MONO_REPO]: false,
									}));
								}}
								data-testid="separaterepo-toggle-btn"
							>
								{separateRepo}
							</Tab>
						</TabList>
					</ToggleStack>
					<Microcopy
						text={
							'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Donec odio. Quisque volutpat mattis eros. Nullam malesuada erat ut turpis.'
						}
					/>
				</Stack>
			)}
			<ExistingConnection
				{...{
					isIntegrationIdValidationValid,
					integrationValidationErrorMsg,
					isSourceControlIntegrationLoading,
				}}
			/>
			<Stack gap="7">
				{Boolean(isDualJourney && !isMonoRepo) ? (
					<Wrap spacing="4">
						<DualCardItem {...{ colorScheme: 'purple' }}>
							<CurrentRepoField
								{...{
									isIntegrationIdValidationValid,
									isSourceControlIntegrationLoading,
									repositoryNamespace: CM_REPOSITORY,
									repositoryIdNamespace: CM_REPOSITORY_ID,
									repositoryBranchNamespace: CM_REPO_BRANCH,
									adoProjectNamespace: CM_ADO_PROJECT_NAME,
									adoOrganizationNamespace:
										CM_ADO_ORGANIZATION,
									accessToken,
								}}
							/>
						</DualCardItem>
						<DualCardItem {...{ colorScheme: 'cyan' }}>
							<CurrentRepoField
								{...{
									isIntegrationIdValidationValid,
									isSourceControlIntegrationLoading,
									repositoryNamespace: EH_REPOSITORY,
									repositoryIdNamespace: EH_REPOSITORY_ID,
									repositoryBranchNamespace: EH_REPO_BRANCH,
									adoProjectNamespace: EH_ADO_PROJECT_NAME,
									adoOrganizationNamespace:
										EH_ADO_ORGANIZATION,
									accessToken,
								}}
							/>
						</DualCardItem>
					</Wrap>
				) : (
					<CurrentRepoField
						{...{
							isIntegrationIdValidationValid,
							isSourceControlIntegrationLoading,
							repositoryNamespace: REPOSITORY,
							adoProjectNamespace: ADO_PROJECT_NAME,
							adoOrganizationNamespace: ADO_ORGANIZATION,
							repositoryBranchNamespace: REPO_BRANCH,
							repositoryIdNamespace: REPOSITORY_ID,
							accessToken,
						}}
					/>
				)}
				<FooterBtnGroups
					{...{
						onNextClick: handleNext,
						onPreviousClick: handlePrev,
						isLoading,
						py: 0,
					}}
				/>
			</Stack>
		</Stack>
	);
};

export default SetupRepository;
