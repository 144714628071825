// src/mocks/handlers.js
import { rest } from 'msw';
import { ProjectMock, ProjectListMock, ProjectCreateMock } from './project';
import {
	cmInstanceLogs,
	cmInstanceLogsMocks,
	cmInstanceLogsTypeMocks,
	EdgeTokenResponseMock,
	EnvironmentMock,
	EnvironmentRestartMock,
	EnvironmentRestartStatusMock,
	EnvironmentsMock,
} from './environment';
import { SystemStatusMock } from './systemStatus';
import { TransfersMock, TransferCompletedMock, TransferMock } from './transfer';
import {
	CreateDeploymentResponseMock,
	DeploymentMock,
	DeploymentsMock,
} from './deployment';
import { BranchesMock } from './branches';
import { TenantClientsMock } from './tenant-clients';
import { MonitoringHistoricalDataMock } from './monitoring-historical-data';
import config from '../app/config/config';
import {
	GithubIntegrationsMock,
	RepositoryTemplatesMock,
	RepositoryProvidersMock,
	RepositoriesMock,
	EnvironmentDeploymentPromoteResponseMock,
	GitHubInstallationStateMock,
} from './repository';
import { CreateRepoResponseMock } from './repository';
import {
	ICreateEdgeClientPayload,
	ICreateEdgeClientResponse,
	ICreateEnvironmentAutomationClientPayload,
	ICreateEnvironmentAutomationClientResponse,
	ICreateOrganizationAutomationClientResponse,
	IGetEnvironmentClientListResponse,
	IGetOrganizationClientListResponse,
} from '../app/models/clientModel';
import { generateClientMock, generateOrganizationClientMock } from './clients';
import { EnvironmentVariablesMock } from './environment-variables';
import {
	IEnvironmentVariableDetails,
	IEnvironmentVariablePayload,
	IGraphQLRequest,
} from '../app/models/environmentModel';
import {
	IValidateIntegrationPayload,
	IValidateRepositoryPayload,
} from '../app/models/repositoriesModel';
import { AzureFeatureFlagsMock } from './azureFeatureFlags';
import { allSitesMock, hostedSitesMock, installationsMock } from './sites';
import {
	HostingInstallationStateMock,
	IValidateInstallationResponseMock,
} from './hosting';
import {
	IGetHostingInstallation,
	IGetHostingProject,
	IValidateInstallationResponse,
} from '../app/models/hostingModel';
import { OrganizationMock } from './organization';
import { IOrganizationIdentityModal } from '../app/services/organizations';
import { IHistoricData } from '../app/models/deployLogger';
import { SourceControlAccessTokenMock } from './accessToken';
import {
	ADOBranches,
	ADOOrganizations,
	ADOProfile,
	ADOProjects,
	ADORepositories,
	GithubBranchesMock,
	GithubRepositoryMock,
} from './externalApi';
import { TransferDetailsMock } from './transferDetails';
import { TransferConfigurationMock } from './transferConfiguration';
import { TransferContentMock } from './transferContent';
import { tenantDetailsMock } from './platformInventory';

export let environmentVariables: IEnvironmentVariableDetails[] = [
	...EnvironmentVariablesMock,
];

const findEnvVarByName = (name: string) => {
	return environmentVariables.find((variable) => variable.name === name);
};

const updateVar = (variable: IEnvironmentVariableDetails) => {
	environmentVariables = environmentVariables.map((varFromArray) => {
		if (variable.name === varFromArray.name) {
			return variable;
		} else {
			return { ...varFromArray };
		}
	});
};

const upsertEnvironmentVariable = (variable: IEnvironmentVariableDetails) => {
	const existingVar = findEnvVarByName(variable.name);
	if (existingVar) {
		updateVar(variable);
	} else {
		environmentVariables.push(variable);
	}
};

const deleteVariable = (variableName: string) => {
	environmentVariables = environmentVariables.filter(
		(varFromArray) => varFromArray.name !== variableName,
	);
};

const {
	projects: {
		get_projects_list,
		create_project,
		project_environments,
		project,
		validate_project,
		environments_Limitation,
		create_project_v2,
	},
	environments: {
		environment_deployments_v2,
		environment,
		environment_deployment_promote_v2,
		environment_variables,
		environment_variable_upsert,
		environment_variable_delete_by_target,
		environment_authoring,
		environment_log_types,
		environment_logs,
		environment_log,
		environment_log_V2,
		environment_edge_token,
		environment_restart,
		environment_editing_secret,
		regenerate_contextid,
	},
	repository: {
		get_repo_branches,
		get_repo_integrations,
		integrate_state,
		integrate_create,
		get_repo_templates,
		get_repo_providers,
		get_repositories,
		validate_repo,
		validate_integration,
		get_repo_integrations_v2,
		validate_integration_V2,
		get_repo_integration,
		delete_integration,
		validate_repo_name_V2,
	},
	tenants: { get_clients, generate_token },
	deployments: {
		deployment_v3,
		deployment_deploy,
		cancel_deployment,
		allDeployments_v3,
		deploymentsByStatus,
	},
	clients: {
		create_edge,
		create_environment_automation,
		create_organization_automation,
		get_environment_list,
		get_organization_list,
		delete: deleteClient,
	},
	hosting: {
		create_project: hosting_create_project,
		get_installations_list,
		get_state: hosting_get_state,
		validate_installation: hosting_validate_installation,
		install: hosting_install,
		delete_project: hosting_delete_project,
		delete_connection: hosting_delete_connection,
	},
	identity: { get_organization, get_user },
	monitoring: { get_historycal_data, signalr_connection },
	transfers: {
		allTransfers,
		transfer,
		transferDetails,
		transferConfiguration,
		transferContent,
	},
	accesToken: { get_source_control_access_token },
	sourceControl: {
		get_user_github_repositories,
		get_github_repo_branches,
		get_ado_profile,
		get_ado_organizations,
		get_ado_branches,
		get_ado_projects,
		get_ado_repositories,
		get_app_installation_github_repositories,
		get_github_repository,
		get_ado_repository,
	},
	platformInventory: { get_tenant },
	statusUrl,
	featureFlags,
} = config;

export const getHandlers = (ms: number) => [
	// mocked endpoints should be added here
	rest.get(get_projects_list.url, (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;
		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(ProjectListMock),
		);
	}),
	rest.post(create_project.url, (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;
		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(ProjectCreateMock),
		);
	}),
	rest.post(project_environments.url(':projectId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;
		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(EnvironmentMock),
		);
	}),
	rest.get(project_environments.url(':projectId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(EnvironmentsMock),
		);
	}),
	rest.get(project_environments.url('mock-project-id-1'), (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json(EnvironmentsMock));
	}),
	rest.post(
		environment_deployments_v2.url('mock-environment-id-1'),
		(req, res, ctx) => {
			const errorCodeParam = req.url.searchParams.get('errorCode');
			const errorCode =
				(errorCodeParam && parseInt(errorCodeParam)) || 200;

			return res(
				ctx.delay(ms),
				ctx.status(errorCode),
				ctx.json(CreateDeploymentResponseMock),
			);
		},
	),
	rest.get(project.url(':projectId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(ctx.delay(ms), ctx.status(errorCode), ctx.json(ProjectMock));
	}),
	rest.put(project.url(':projectId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(ctx.delay(ms), ctx.status(errorCode), ctx.json(ProjectMock));
	}),
	rest.delete(project.url(':projectId'), (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json({ status: 'ok' }));
	}),
	rest.get(statusUrl, (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.json(SystemStatusMock));
	}),
	rest.get(
		environment_deployments_v2.url(':environmentId'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(DeploymentsMock),
			);
		},
	),
	rest.get(
		environment_deployments_v2.url('mock-environment-id-1'),
		(req, res, ctx) => {
			const errorCodeParam = req.url.searchParams.get('errorCode');
			const errorCode =
				(errorCodeParam && parseInt(errorCodeParam)) || 200;

			return res(
				ctx.delay(ms),
				ctx.status(errorCode),
				ctx.json(DeploymentsMock),
			);
		},
	),
	rest.delete(environment.url(':environmentId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 202;

		return res(ctx.delay(ms), ctx.status(errorCode));
	}),
	rest.put(environment.url(':environmentId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(ctx.delay(ms), ctx.status(errorCode));
	}),
	rest.get(get_repo_branches.url(':repositoryId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(BranchesMock),
		);
	}),
	rest.get(get_clients.url, (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(TenantClientsMock),
		);
	}),
	rest.post(generate_token.url(':clientId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json({ token: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtp' }),
		);
	}),
	rest.get(deployment_v3.url(':deploymentId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(DeploymentMock),
		);
	}),
	rest.post(deployment_deploy.url(':deploymentId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(ctx.delay(ms), ctx.status(errorCode));
	}),
	rest.get(environment.url('mock-environment-id-1'), (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json(EnvironmentMock));
	}),
	rest.get(environment.url(':environmentId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(EnvironmentMock),
		);
	}),
	rest.get(get_repo_integrations.url, (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(GithubIntegrationsMock),
		);
	}),
	rest.get(integrate_state.url, (req, res, ctx) => {
		return res(
			ctx.delay(ms),
			ctx.status(200),
			ctx.text(GitHubInstallationStateMock),
		);
	}),
	rest.post(integrate_create.url, (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(ctx.delay(ms), ctx.status(errorCode));
	}),
	rest.get(get_repo_templates.url, (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(RepositoryTemplatesMock),
		);
	}),
	rest.get(get_repo_providers.url, (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(RepositoryProvidersMock),
		);
	}),
	rest.post(get_repositories.url, (req, res, ctx) => {
		return res(
			ctx.delay(ms),
			ctx.status(200),
			ctx.json(CreateRepoResponseMock),
		);
	}),
	rest.post(cancel_deployment.url(':deploymentId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(ctx.delay(ms), ctx.status(errorCode));
	}),
	rest.post(
		environment_deployment_promote_v2.url(
			'mock-environment-id-1',
			'deploymentId',
		),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(EnvironmentDeploymentPromoteResponseMock),
			);
		},
	),
	rest.post(
		environment_deployment_promote_v2.url(
			'mock-environment-id-1',
			':deploymentId',
		),
		(req, res, ctx) => {
			const errorCodeParam = req.url.searchParams.get('errorCode');
			const errorCode =
				(errorCodeParam && parseInt(errorCodeParam)) || 200;

			return res(ctx.delay(ms), ctx.status(errorCode));
		},
	),
	rest.post<ICreateEdgeClientPayload>(create_edge.url, (req, res, ctx) => {
		const { name, description } = req.body;

		const response: ICreateEdgeClientResponse = {
			name,
			description,
			clientId: '123456',
			clientSecret: '123456-123456-123456-123456',
		};

		return res(ctx.delay(ms), ctx.status(200), ctx.json(response));
	}),
	rest.post<ICreateEnvironmentAutomationClientPayload>(
		create_environment_automation.url,
		(req, res, ctx) => {
			const { name, description } = req.body;

			const response: ICreateEnvironmentAutomationClientResponse = {
				name,
				description,
				clientId: '123456',
				clientSecret: '123456-123456-123456-123456',
			};

			return res(ctx.delay(ms), ctx.status(200), ctx.json(response));
		},
	),
	rest.post<ICreateEnvironmentAutomationClientPayload>(
		create_organization_automation.url,
		(req, res, ctx) => {
			const { name, description } = req.body;

			const response: ICreateOrganizationAutomationClientResponse = {
				name,
				description,
				clientId: '123456',
				clientSecret: '123456-123456-123456-123456',
			};

			return res(ctx.delay(ms), ctx.status(200), ctx.json(response));
		},
	),
	rest.get(get_environment_list.url, (req, res, ctx) => {
		const response: IGetEnvironmentClientListResponse = {
			items: Array.from(Array(12)).map((_, index) =>
				generateClientMock(index),
			),
		};

		return res(ctx.delay(ms), ctx.status(200), ctx.json(response));
	}),
	rest.get(get_organization_list.url, (req, res, ctx) => {
		const response: IGetOrganizationClientListResponse = {
			items: Array.from(Array(12)).map((_, index) =>
				generateOrganizationClientMock(index),
			),
		};

		return res(ctx.delay(ms), ctx.status(200), ctx.json(response));
	}),
	rest.delete(deleteClient.url(':id'), (_, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200));
	}),
	rest.get(get_repositories.url, (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(RepositoriesMock),
		);
	}),
	rest.get(environment_variables.url(':environmentId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(environmentVariables),
		);
	}),
	rest.post(
		environment_variable_upsert.url(':environmentId', ':variableName'),
		(req, res, ctx) => {
			const errorCodeParam = req.url.searchParams.get('errorCode');
			const errorCode =
				(errorCodeParam && parseInt(errorCodeParam)) || 200;

			upsertEnvironmentVariable({
				...{ name: req.params.variableName },
				...(req.body as IEnvironmentVariablePayload),
			} as IEnvironmentVariableDetails);

			return res(
				ctx.delay(ms),
				ctx.status(errorCode),
				ctx.json(environmentVariables),
			);
		},
	),
	rest.delete(
		environment_variable_delete_by_target.url(
			':environmentId',
			':variableName',
			'',
		),
		(req, res, ctx) => {
			const errorCodeParam = req.url.searchParams.get('errorCode');
			const errorCode =
				(errorCodeParam && parseInt(errorCodeParam)) || 200;

			deleteVariable(req.params.variableName[0]);

			return res(
				ctx.delay(ms),
				ctx.status(errorCode),
				ctx.json(environmentVariables),
			);
		},
	),
	rest.post<IValidateRepositoryPayload, any>(
		validate_repo.url,
		(_, res, ctx) => {
			return res(
				ctx.json({
					isValid: true,
					message: '',
				}),
			);
		},
	),
	rest.get(featureFlags.url.split('?')[0], (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;
		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(AzureFeatureFlagsMock),
		);
	}),
	rest.post<IValidateIntegrationPayload>(
		validate_integration.url,
		(_, res, ctx) => {
			return res(
				ctx.json({
					isValid: true,
					message: '',
				}),
			);
		},
	),
	rest.post<string>(validate_project.url, (_, res, ctx) => {
		return res(
			ctx.json({
				isValid: true,
				message: '',
			}),
		);
	}),
	rest.post<IGraphQLRequest>(
		environment_authoring.url('testing-mock.example.com'),
		(_, res, ctx) => {
			return res(ctx.delay(ms), ctx.status(200), ctx.json(allSitesMock));
		},
	),
	rest.post<IGraphQLRequest>(
		environment_authoring.url('environmentPublishStatus.com'),
		(_, res, ctx) => {
			return res(ctx.delay(ms), ctx.status(200), ctx.json(allSitesMock));
		},
	),
	rest.get<IGetHostingProject[]>(
		hosting_create_project.url,
		(_, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(hostedSitesMock),
			);
		},
	),
	rest.post(hosting_create_project.url, (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json({ status: 'ok' }));
	}),

	rest.get<IGetHostingInstallation[]>(
		get_installations_list.url,
		(req, res, ctx) => {
			return res(ctx.delay(ms), ctx.json(installationsMock));
		},
	),
	rest.get(hosting_get_state.url, (req, res, ctx) => {
		return res(
			ctx.delay(ms),
			ctx.status(200),
			ctx.text(HostingInstallationStateMock),
		);
	}),

	rest.get<IValidateInstallationResponse>(
		hosting_validate_installation.url('integrationId').split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(IValidateInstallationResponseMock),
			);
		},
	),
	rest.post(hosting_install.url, (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json({}));
	}),
	rest.get(
		environment_log_types.url('mock-environment-id-3'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(cmInstanceLogsTypeMocks),
			);
		},
	),
	rest.get(
		environment_logs.url('mock-environment-id-3').split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(cmInstanceLogsMocks),
			);
		},
	),
	rest.get(
		environment_log.url(
			'mock-environment-id-3',
			cmInstanceLogsMocks[0].name,
		),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(cmInstanceLogs),
			);
		},
	),
	rest.get(
		environment_log_V2.url(
			'mock-environment-id-3',
			cmInstanceLogsMocks[0].name,
		),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(cmInstanceLogs),
			);
		},
	),
	rest.head(
		environment_log.url(
			'mock-environment-id-3',
			cmInstanceLogsMocks[0].name,
		),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(cmInstanceLogs),
			);
		},
	),

	rest.get<IOrganizationIdentityModal>(
		get_organization.url,
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(OrganizationMock),
			);
		},
	),
	rest.get<IHistoricData>(
		get_historycal_data.url('mock-deployment-id-3'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(MonitoringHistoricalDataMock),
			);
		},
	),
	rest.get(
		environments_Limitation.url('mock-project-id-3'),
		(req, res, ctx) => {
			return res(ctx.delay(ms), ctx.status(200), ctx.json(null));
		},
	),
	rest.get(signalr_connection.url, (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json(null));
	}),
	rest.post(
		'https://xmcloud-monitoring-api-dev.sitecore-staging.cloud/deploymentHub/negotiate',
		(req, res, ctx) => {
			return res(ctx.delay(ms), ctx.status(200), ctx.json(null));
		},
	),
	rest.get(allDeployments_v3.url(1, 10).split('?')[0], (req, res, ctx) => {
		const data = DeploymentsMock.map((d) => ({
			...d,
			projectName: 'Project name',
			environmentName: 'Environment name',
		}));
		return res(ctx.delay(ms), ctx.status(200), ctx.json({ data: data }));
	}),
	rest.get(allTransfers.url(1, 10).split('?')[0], (req, res, ctx) => {
		let data = TransferCompletedMock.map((d) => ({
			...d,
			projectName: 'Project name',
			environmentName: 'Environment name',
		}));
		if (req.url.searchParams.get('filter') === 'all') {
			data = TransfersMock.map((d) => ({
				...d,
				projectName: 'Project name',
				environmentName: 'Environment name',
			}));
		}
		return res(
			ctx.delay(ms),
			ctx.status(200),
			ctx.json({ data: data, totalCount: data.length }),
		);
	}),
	rest.get(transfer.url(':transferId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(TransferMock),
		);
	}),
	rest.get(transferDetails.url(':transferId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(TransferDetailsMock),
		);
	}),
	rest.get(transferConfiguration.url(':transferId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(TransferConfigurationMock),
		);
	}),
	rest.get(transferContent.url(':transferId'), (req, res, ctx) => {
		const errorCodeParam = req.url.searchParams.get('errorCode');
		const errorCode = (errorCodeParam && parseInt(errorCodeParam)) || 200;

		return res(
			ctx.delay(ms),
			ctx.status(errorCode),
			ctx.json(TransferContentMock),
		);
	}),
	rest.get(
		environment_edge_token.url(EnvironmentMock.id),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(EdgeTokenResponseMock),
			);
		},
	),
	rest.delete(
		hosting_delete_project.url(':hostingProjectId'),
		(req, res, ctx) => {
			const errorCodeParam = req.url.searchParams.get('errorCode');
			const errorCode =
				(errorCodeParam && parseInt(errorCodeParam)) || 202;

			return res(ctx.delay(ms), ctx.status(errorCode));
		},
	),
	rest.delete(
		hosting_delete_connection.url(':hostingConnectionId'),
		(req, res, ctx) => {
			const errorCodeParam = req.url.searchParams.get('errorCode');
			const errorCode =
				(errorCodeParam && parseInt(errorCodeParam)) || 202;

			return res(ctx.delay(ms), ctx.status(errorCode));
		},
	),
	rest.get(
		get_repo_integrations_v2.url(1, 10).split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json({
					data: GithubIntegrationsMock,
					totalCount: 8,
					pageSize: 10,
					pageNumber: 1,
				}),
			);
		},
	),
	rest.get(get_user.url, (req, res, ctx) => {
		return res(
			ctx.delay(ms),
			ctx.status(200),
			ctx.json({
				id: '123abc',
				email: 'user.dk@sitecore.com',
				givenName: 'sitecore',
				familyName: 'dk',
				nickName: 'sitecoredk',
			}),
		);
	}),
	rest.get(
		validate_integration_V2.url('test-integration-id-1').split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json({ isValid: true, message: '' }),
			);
		},
	),
	rest.get(
		get_source_control_access_token
			.url('test-integration-id-1')
			.split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(SourceControlAccessTokenMock),
			);
		},
	),
	rest.get(
		get_user_github_repositories.url(1, 100).split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(GithubRepositoryMock),
			);
		},
	),
	rest.get(
		get_github_repo_branches
			.url('test-integration-name-1', 'test-repo')
			.split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(GithubBranchesMock),
			);
		},
	),
	rest.get(get_ado_profile.url.split('?')[0], (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json(ADOProfile));
	}),
	rest.get(
		get_ado_organizations.url('Test-User').split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(ADOOrganizations),
			);
		},
	),
	rest.get(
		get_ado_projects.url('mock_account').split('?')[0],
		(req, res, ctx) => {
			return res(ctx.delay(ms), ctx.status(200), ctx.json(ADOProjects));
		},
	),
	rest.get(
		get_ado_repositories.url('mock_account', 'mock_project').split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(ADORepositories),
			);
		},
	),
	rest.get(
		get_ado_branches
			.url('mock_account', 'mock_project', 'mock_repository')
			.split('?')[0],
		(req, res, ctx) => {
			return res(ctx.delay(ms), ctx.status(200), ctx.json(ADOBranches));
		},
	),

	rest.get(
		get_repo_integration.url('test-integration-id-1').split('?')[0],

		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(GithubIntegrationsMock[0]),
			);
		},
	),
	rest.get(
		get_app_installation_github_repositories.url(1, 49).split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json({
					total_count: 3,
					repositories: RepositoriesMock,
				}),
			);
		},
	),
	rest.get(
		environment_restart.url('mock-environment-id-1'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(EnvironmentRestartStatusMock),
			);
		},
	),
	rest.get(
		environment_restart.url('mock-environment-id-2'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(EnvironmentRestartStatusMock),
			);
		},
	),
	rest.get(
		environment_restart.url('mock-environment-id-3'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(EnvironmentRestartStatusMock),
			);
		},
	),
	rest.post(
		environment_restart.url('mock-environment-id-1'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(EnvironmentRestartMock),
			);
		},
	),
	rest.get(deploymentsByStatus.url, (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json([]));
	}),
	rest.get(
		environment_editing_secret.url('mock-environment-id-1'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json('MOCK_API_KEY'),
			);
		},
	),
	rest.get(get_tenant.url('mock-platform-tenant-id'), (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json(tenantDetailsMock));
	}),
	rest.get(
		get_tenant.url('0b660dcd-69f8-4eb7-45e3-08dbca4c7c1f'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json(tenantDetailsMock),
			);
		},
	),
	rest.post(
		regenerate_contextid.url('mock-environment-id-1'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json({
					...EnvironmentMock,
					previewContextId: 'new-preview-context-id',
					liveContextId: 'new-live-context-id',
				}),
			);
		},
	),
	rest.delete(
		delete_integration.url('test-integration-id-1'),
		(req, res, ctx) => {
			return res(ctx.delay(ms), ctx.status(200), ctx.json(''));
		},
	),
	rest.get(
		validate_repo_name_V2
			.url('test-repo', 'test-integration-id-1')
			.split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json({ isValid: true, message: '' }),
			);
		},
	),
	rest.post(create_project_v2.url, (req, res, ctx) => {
		return res(ctx.delay(ms), ctx.status(200), ctx.json(ProjectCreateMock));
	}),
	rest.get(
		get_github_repository.url('owner', 'test-repo'),
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json({
					id: 1296269,
					name: 'test-repo',
				}),
			);
		},
	),
	rest.get(
		get_ado_repository
			.url('test-organization', 'test-project', 'test-repo')
			.split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json({
					id: 1296269,
					name: 'test-ado-repo',
				}),
			);
		},
	),
	rest.patch(
		get_ado_repository
			.url('test-organization', 'test-project', 'test-repo')
			.split('?')[0],
		(req, res, ctx) => {
			return res(
				ctx.delay(ms),
				ctx.status(200),
				ctx.json({ status: 'ok' }),
			);
		},
	),
];
