const app = {
	name: 'Name',
	id: 'ID',
	dateCreated: 'Date created',
	projects: 'Projects',
	deployments: 'Deployments',
	contenttransfer: 'Content transfer',
	credentials: 'Credentials',
	systemStatus: 'System status',
	documentation: 'Documentation',
	connections: 'Connections',
	settings: 'Settings',
	next: 'Next',
	cancel: 'Cancel',
	save: 'Save',
	logout: 'Log out',
	loginAgain: 'Log in again',
	manageAccount: 'Manage account',
	close: 'Close',
	helpTitle: 'Create a support case',
	help: 'Help',
	options: 'Options',
	delete: 'Delete',
	XMCloud: 'XM Cloud',
	details: 'Project details',
	placeHolderText: 'Change your filter or search parameters and try again.',
	region: 'Region',
	ok: 'OK',
	create: 'Create',
	revoke: 'Revoke',
	deleteConfirm:
		'Delete {bold} forever? This action is permanent and cannot be undone.',
	subTitlePlaceHolder: 'Create your first.',
	noItemFound: 'No {item} found',
	branches: 'branches',
	deploy: 'Deploy',
	home: 'Home',
	restart: 'Restart',
	type: 'Type',
};

const projects = {
	projectDetailsEdit: 'Project details successfully updated',
	requiredName: 'A project name is needed to continue',
	noMatchingName: 'The input value doesn’t match the project name.',
	create: 'Create project',
	all: 'All projects',
	my: 'My projects',
	deleteProject: 'Delete project',
	lastUpdated: 'Last updated',
	project: 'Project',
	projectId: 'Project ID',
	canNotBeDeleted: 'Delete all environments before deleting this project',
	required: 'The input value is needed to continue',
	canNotCreateProject:
		'You cannot create another project. Delete a project or upgrade your subscription.',
	nuOfEnvironments: 'Number of environments',
	delete: 'Delete project',
	edit: 'Edit project',
	details: 'Project details',
	editTitle: 'Edit project details',
	nameTitle: 'Project name',
	selectConnection: 'Select an existing {provider} connection',
	createConnection: 'Create a new {provider} connection',
	connectionInfo:
		"Creating a new connection will require 'All repositories' access to automate the repository creation process.",
	connectionInfo2:
		'Creating a new connection will require access to the repositories that contain your project code.',
	connectionInfo3:
		'Creating a new connection will allow access to the repositories where your project code is stored.',
	uDontHaveProject: 'No projects yet',
	nuOfEnv: 'Nu of env.',
	vercelMaxNameLength:
		'Vercel project name cannot be longer than 63 characters',
	error1: 'Some of the environments may not be up-to-date. Please review the details for each environment, as updates can be made on the environment page.',
	projectType: 'Project type',
	webApp: 'Web app',
	contentService: 'XM Cloud content service',
};

const environments = {
	environmentDetailsEdit: 'Environment details successfully updated',
	linkedToAnotherBranch: 'Environment successfully linked to a branch',
	requiredEnvironmentName: 'An environment name is needed to continue',
	invalidEnvironmentName:
		'Environment name can only contain letters, numbers, space, and the following characters: -, _, ., !',
	buildAndDeploy: 'Build and deploy',
	variables: 'Manage variables',
	environments: 'Environments',
	environment: 'Environment',
	create: 'Create environment',
	editDetails: 'Edit environment details',
	delete: 'Delete environment',
	linkEnvironment: 'Link environment to another branch',
	autoDeploy: 'auto deploy based on commits',
	openDashboard: 'Open dashboard',
	restart: 'Restart environment',
};

const environment = {
	deployment: 'Deployments',
	sites: 'Sites',
	details: 'Environment details',
	logs: 'Logs',
	variables: 'Variables',
	devSettings: 'Developer settings',
	crateSite: 'Create site',
	id: 'Environment ID',
	type: 'Type',
	region: 'Selected region',
	baseImageVersion: 'Base image version',
	edgeToken: 'Edge token value',
	hostName: 'Environment host name',
	cmUrl: 'CM Instance URL',
	dashboard: 'Dashboard',
	delete: 'Delete environment',
	environment: 'environment',
	requiredBranch: 'A branch is needed to continue',
	linkToBranchTitle: 'Link environment to another branch',
	link: 'Link',
	linkedSuccessfully: 'Environment successfully linked to {branch} branch',
	hostNamesAndUrls: 'Hostnames, URLs, and tokens',
	launchIDE: 'Launch IDE',
	generateSecret: 'Generate secret',
	generateToken: 'Generate token',
	noMatchingName: 'The input value doesn’t match the environment name.',
	graphQlEndpoint: 'CM Preview Endpoint',
	edgePreviewEndpoint: 'Preview GraphQL IDE',
	edgeDeliveryEndpoint: 'Live GraphQL IDE (Experience Edge)',
	editingSecret: 'JSS_Editing_Secret',
	generatePreviewToken: 'Generate Preview API token',
	generatePreviewTokenTooltip:
		'Explore the Preview API and query the CM instance content',
	generateDeliveryToken: 'Generate Delivery API token',
	generateDeliveryTokenTooltip:
		'Explore the Delivery API and query the content published to Experience Edge',
	generateEditingSecret: 'Generate JSS_Editing_Secret',
	generateEditingSecretTooltip:
		'Generate the JSS_Editing_Secret variable for local development',
	authoringGraphQlEndpoint: 'Authoring GraphQL IDE',
	authoringGraphQlEndpointTooltip:
		'Explore the APIs for managing and authoring content in the CM instance. To enable, set the Sitecore_GraphQL_ExposePlayground environment variable to true.',
	restart: 'Restart environment',
	restarting: 'Restart in progress',
	requestRestart:
		'Start an environment restart process. A restart won’t rebuild or redeploy your environment.',
	restartDialogDescription:
		' The environment will be unavailable for a short while during the restart process. Do you want to continue?',
	restartDialogConfirm: 'Restart',
	restartDialogCancel: 'Cancel',
	restartInitiated:
		'A restart was successfully initiated for this environment',
	restartSuccessTitle: 'Restart successful',
	restartSuccessDescription: 'Environment restart was successful',
	restartFailedTitle: 'Restart failed',
	restartFailedDescription: 'Environment restart took longer than 20 minutes',
	contextIdPreview: 'Context ID (Preview)',
	contextIdLive: 'Context ID (Live)',
	repositoryChanged:
		'The repository associated with {boldText} changed on {variableTxt2}.',
	regenerate: 'Regenerate',
	regenerateContextId: 'Regenerate Context IDs',
	regenerateDesc1:
		'Permanently revoke the current Context IDs and generate new ones? All apps and services using the current Context IDs will need to be updated with the new Context ID. You must also redeploy the XM Cloud environment. ',
	regenerateDesc2:
		'Before revoking the current Context IDs, copy and save them:',
	currentContextPreview: 'Current Context ID (Preview)',
	currentContextLive: 'Current Context ID (Live)',
};

const connections = {
	requiredConnection:
		'You must select a connection or create a new one to continue',
	canNotValidateConnection: 'There is a problem with validation',
	descriptionText:
		'Create and view your authorized Git-based source control connections. On the Hosting tab, you can manage your hosting providers.',
	sourceControl: 'Source control',
	hosting: 'Hosting',
	create: 'Create connection',
	delete: 'Delete connection',
	providerName: 'Provider',
	connectionName: 'Name',
	connectionId: 'Connection ID',
	createdBy: 'Created by',
	createdAt: 'Date created',
	github: 'GitHub',
	ado: 'Azure DevOps',
	vercel: 'Vercel',
	gitHubInstallationFailed:
		'Integration failed. Something went wrong. Uninstall your app from GitHub account manually. You can close this tab and try again.',
	gitHubInstllationSuccess: 'Integration succeeded. You can close this tab.',
	adoInstallationFailed:
		'Integration failed. Something went wrong.You can close this tab and try again.',
	adoInstallationSuccess: 'Integration succeeded. You can close this tab.',
	deleteConnectionSuccess: 'Connection was successfully deleted',
	deleteConfirmation: 'Are you sure you want to delete this connection?',
	deleteValidationMessage:
		'Only the user who created the connection can delete it',
	deleteConnectionTooltipInfo:
		'Delete all associated projects before deleting this connection',
	sourceControlProvider: 'Source control provider',
	chooseProvider: 'Choose a source control provider',
	error1: 'Some things went wrong. Please try later.',
	noSourceControl: 'Project is not linked to a source control',
};
const repository = {
	requiredRepositoryName: 'A repository name is needed to continue',
	invalidRepositoryName: 'Repository name is invalid',
	account: 'Repository account',
	name: 'Repository name',
	repositoryLinkedDate: 'Repository linked on',
};

const info = {
	projectsInfo: 'Learn more about the available project templates.',
	redirectToHomeToApplyChanges:
		'You will redirect to home page in order to have these changes applied.',
};

const documentation = {
	help: 'Help',
	releaseNotes: 'Release notes',
};

const environmentVariable = {
	name: 'Name',
	value: 'Value',
	secret: 'Secret',
	renderingHost: 'Rendering host name',
	create: 'Create variable',
	edit: 'Edit variable',
	delete: 'Delete variable',
	save: 'Save',
	no: 'No',
	yes: 'Yes',
	createdSuccessfully: 'Environment variable created successfully',
	updatedSuccessfully: 'Environment variable updated successfully',
	deletedSuccessfully: 'Environment variable deleted successfully',
	invalidCharacters:
		'Only alphanumeric characters and underscore are allowed. Furthermore, the name should start with a letter',
	rhInvalidCharacters:
		'Rendering host name can only contain letters, numbers, and the following characters: _, ., -',
	requiredValue: 'Variable value is needed to continue',
	requiredName: 'Variable name is needed to continue',
	requiredRH: 'Rendering host name is needed to continue',
	alreadyExist: 'Variable name already exists',
	allTargetExist: 'Variable already exists for ALL targets',
	diffTargetExist:
		'Variable already exists for a target. Cannot create for ALL targets',
	deleteConfirmation: 'Are you sure you want to delete this variable?',
	target: 'Target',
	targetAll: 'All',
	targetCM: 'CM',
	targetRH: 'Rendering host',
	secretInfo: 'A secret variable is encrypted and you cannot view its value.',
	doubleQuoteNotAllowed:
		'Environment variable value can not contain double quotes.',
};

const settings = {
	experimentalFeatures: 'Experimental features',
	newEnvironmentDetailsTitle: 'New environment details',
	newEnvironmentDetailsDescription:
		'Environment details feature provides detailed information about the environment, its sites and deployments. This also provides the ability to do a Vercel deployment for a site. To view the environment details, in the Projects page, click the environment name.',
	betaXMCloudUICardTitle: 'Beta XM Cloud UI',
	betaXMCloudUIDescription:
		'Enabling this feature will allow you to use the brand new XM Cloud user interface. This feature is beta version at the moment.',
	info: 'Experimental features are subject to change and are provided without support',
};

const deployments = {
	createDate: 'Date created',
	latestDeployment: 'Latest deployment',
	status: 'Status',
	viewLogs: 'View deployment logs',
	deployBuildArtifact: 'Deploy build artifact to another environment',
	queued: 'Queued',
	inprogress: 'In progress',
	complete: 'Complete',
	failed: 'Failed',
	skipped: 'Skipped',
	cancel: 'Cancel deployment',
	cancelConfirmation: 'Are you sure you want to cancel deployment?',
	buildToOtherEnvironmentTitle: 'Deploy build to another environment',
	deploy: 'Deploy',
	cannotBePromoted:
		'cannot be used as a promotion target because it is associated with a linked source control branch',
	unavailable: 'Unavailable',
	selectEnvironment: 'Please select an environment',
};

const sites = {
	alertInfo:
		'Your site {bold} is not hosted. We recommend that you set up hosting for it.',
	manySitesInfo:
		'Some of your sites are not hosted. We recommend that you set up hosting for them.',
	create: 'Create site',
	createSite: 'Create your first site',
	renderingHost: 'Rendering host',
	host: 'Host',
	setupHost: 'Set up hosting',
	unlinkHost: 'Unlink site from host',
	publish: 'Publish all sites',
	nositesToShow: 'No sites to show',
	environmentNotReady: 'Environment is not ready',
	linkToVercel: 'Create Vercel project and link to site',
	projectName: 'Project name',
	selectInstallation: 'Select an existing Vercel installation',
	helperText:
		'You must select an installation or create a new one to continue.',
	createVercel: 'Create a new Vercel installation',
	vercelInfo1:
		"'All Projects' access must be granted on the Vercel installation",
	vercelInfo2:
		'must be active on the Vercel installation and must use the same GitHub account as the XM Cloud / GitHub integration',
	vercelInfo3:
		'To create a Vercel project in a Vercel team account, your GitHub repository must be in a GitHub organization account',
	githubIntegration: 'GitHub integration',
	publishSuccess: 'The site has been published successfully',
	successInstallations: 'Successfully linked to Vercel installation',
	vercelInstallSuccess: 'Vercel installation succeeded.',
	vercelInstallFailed:
		'Vercel installation failed. Uninstall your integration from Vercel manually. You can close this tab and try again.',
	hostingUrl: 'Hosting URL',
	noSetup: 'No host set up',
	createAndLink: 'Create and link',
	unlink: 'Unlink',
	unlinkSiteConfirmation: (siteName: string) =>
		`Are you sure you want to unlink ${siteName} from host?`,
	unlinkedSuccessfully: 'Site successfully unlinked from the host',
	deleteFromProvider: 'Delete the project from Vercel',
	adoNotSupportVercel: 'Vercel does not support ADO scource control',
};

const confirmation = {
	AreYouSure: 'Are you sure?',
	cancelDeploymentTitle: 'Cancel deployment',
	cancelDeployment: 'Are you sure you want to cancel deployment?',
};

const cmInstanceLog = {
	name: 'Name',
	lastModified: 'Last modified',
	size: 'Size',
	time: 'Time',
	download: 'Download logs',
	downloadAll: 'Download all logs',
	logs: 'Logs',
	category: 'Category',
	allLogs: 'All logs',
	noLogsAvailable: `No Logs available`,
	warn: 'Warn',
	warning: 'Warning',
	error: 'Error',
};

const deploymentLogs = {
	deploymentLogs: 'Deployment logs',
	deploymentLog: 'Deployment Log',
	build: 'Build',
	deployment: 'Deployment',
	provisioning: 'Provisioning',
	postActions: 'Post actions',
	completed: 'Completed',
	failed: 'Failed',
	inProgress: 'In progress',
	pending: 'Pending',
	deploymentCanceled: 'Deployment was canceled.',
	deploymentFailed: 'Deploy failed',
	deploymentProgress: 'Deploy in progress',
	deploymentCompleted: 'Deploy completed',
	deploymentNotStarted: 'Deployment has not started',
	detailsBelow: 'Details below',
	xmcloud: 'Go to XM Cloud',
	noLogsFound: 'No logs found for deployment with id: ',
	dnsInprogress: 'DNS resolution in progress',
	promote: 'Promote',
};
const editCreateEnvironment = {
	environmentName: 'Environment name',
	isThisProduction: 'Is this a production environment?',
	linkToBranch: 'Link to branch',
	triggerDeployment: 'Auto deploy on push to repository',
	editTitle: 'Edit environment details',
	nameValidation1:
		'An initial deployment environment name is needed to continue',
	nameValidation2: 'An environment name is needed to continue',
	nameValidation3: 'An environment name cannot be longer than 100 characters',
	nameValidation4:
		'Environment name can only contain letters, numbers, space, and the following characters: -, _, ., !',
	needToContinue: 'This is needed to continue',
	tenantTypeValidation1:
		'You cannot create another production environment in this project. Delete a production environment or use another project.',
	tenantTypeValidation2:
		'You cannot create another non-production environment in this project. Delete a non-production environment or use another project.',
	tenantTypeValidation3:
		'You cannot create another production environment. Delete a production environment or upgrade your subscription.',
	tenantTypeValidation4:
		'You cannot create another non-production environment. Delete a non-production environment or upgrade your subscription.',
	createTitle: 'Create environment',
	yes: 'Yes',
	no: 'No',
	none: 'None',
	branchHelperText1: 'Once this is set, {bold}',
	branchHelperText2: 'it cannot be changed',
	editSuccess: 'Environment details have been updated successfully',
	errorPostfix:
		' Nevertheless, you can create a new environment without linking it to a branch.',
	warningSuffix: ' Nevertheless, you can edit the environment name.',
};
const credentials = {
	descriptionText:
		'Create credentials to access your environment APIs. If you create credentials at an organization level, it will grant access to all environments in your organization',
	create: 'Create credentials',
	generate: 'Generate',
	done: 'Done',
	automationClient: 'Automation client',
	edgeClient: 'Edge administration client',
	automation: 'Automation',
	orgAutomation: 'Organization automation',
	edge: 'Edge administration',
	organization: 'Organization',
	project: 'Project',
	environment: 'Environment',
	label: 'Label',
	description: 'Description',
	clientId: 'Client ID',
	clientSecret: 'Client secret',
	createdDate: 'Date created',
	location: 'Location',
	type: 'Type',
	revoke: 'Revoke client',
	revokeConfirmation: 'Are you sure you want to revoke this?',
	revokeSuccess: 'Client successfully revoked',
	labelRequired: 'Label is required',
	projectRequired: 'Please select a project',
	environmentRequired: 'Please select an environment',
	minimumLengthMessage:
		'The field must be a string with a minimum length of {minLength}',
	maximumLengthMessage:
		'The field must be a string with a maximum length of {maxLength}',
	clientSecretWarningText:
		'This secret is only shown once and cannot be shown again. Be sure to copy it now.',
	noEnvInfo:
		'No environments in project {bold}. Create an environment before creating environment credentials.',
};

const placeHolders = {
	noItemCreatedYet: (item: string) => `No ${item} created yet`,
};

const createDeployment = {
	createTitle: 'Create deployment',
	selectProject: 'Select project',
	selectEnvironment: 'Select environment',
	projectRequired: 'Please select a project',
	environmentRequired: 'Please select an environment',
	select: 'Select…',
	noEnvironment: 'No environments available for this project',
	noEnvInfo:
		'No environments in project {bold}. Create an environment before creating deployment.',
};

const transfers = {
	transfersTitle: 'Content transfer',
	transfersDescription:
		'Transfer content between environments. Lorem ipsum dolor sit venenatis.',
	inProgress: 'In progress',
	completed: 'Completed',
	create: 'Create transfer',
	name: 'Name',
	source: 'Source',
	destination: 'Destination',
	date: 'Date',
	itemsTransfered: 'Items transfered',
	status: 'Status',
	explanation: 'Explanation of transfer statuses',
	progress: ' - Transfer is in progress',
	complete: ' - The entire transfer process is completed',
	queued: " - Transfer haven't started yet",
	failed: ' - Transfer failed. Check transfer details for more information.',
	noCompletedTransfersTitle: 'No completed transfers yet',
	noPendingTransfersTitle: 'No pending transfers',
	transferAgain: 'Transfer again',
	goToXmc: 'Go to XM Cloud',
};
const transferDetails = {
	status: 'Status',
	details: 'Details',
	initializationDescription:
		'Pre-validation and initialization on the source environment',
	transferringContentDescription:
		'Transferring items from source to target environment',
	movingToDbDescription: 'Copying items to the target environment’s database',
	transferCompleted: 'Transfer completed',
	transferringContentCompleted: 'Transferring content completed',
	canEditItemsMessage:
		'You can edit the items in the destination environment while moving items to database. All the changes will be synced.',
	labelCreatedBy: 'Created by',
	labelCreatedOn: 'Created on',
	labelSourceEnv: 'Source environment',
	labelTargetEnv: 'Destination environment',
	labelSelectedItems: 'Selected items and transfer options',
};

const createTransfer = {
	transferDetailsStepTitle: 'Transfer details',
	transferDetailsLabel: 'Transfer details',
	itemSelectionStepTitle: 'Item selection',
	mergeStrategiesStepPopoverTitle: 'Explanation of merge strategies',
	mergeStrategiesStepTitle: 'Merge strategies',
	transferOptionsStepDescription: 'Select how the items will be transferred',
	transferOptionsStepSelectedItemsTitle: 'Selected items',
	transferOptionsStepSelectedItemsBulkActionsTitle: 'Bulk actions',
	transferOptionsStepSelectedItemsAllItemsTitle: 'All items',
	reviewAndTransferStepTitle: 'Review and transfer',
	transferDetailsNameField: 'Name',
	transferDetailsNamePlaceholder:
		'e.g. Catalog. This will help identifying the transfer later.',
	transferDetailsSourceEnvironmentField: 'Source environment',
	transferDetailsSourceEnvironmentPlaceholder:
		'From where you want to take items',
	transferDetailsTargetEnvironmentField: 'Destination environment',
	transferDetailsTargetEnvironmentPlaceholder:
		'Where do you want to transfer items',
	createTransferNextButtonTitle: 'Next',
	createTransferTitle: 'Create transfer',
	createTransferDescription: 'Transfer content between environments.',
	requiredTransferName: 'A transfer name is needed to continue',
	transferNameMaxCharacters:
		'Transfer name cannot be longer than 100 characters',
	requiredSourceEnvironment: 'Source environment is needed to continue',
	requiredDestinationEnvironment:
		'Destination environment is needed to continue',
	selectItemsLabel: 'Select items to transfer',
	selectParentTreeNode: 'Select ',
	unselectParentTreeNode: 'Unselect ',
	selectSubItemsOfParentTreeNode: 'Select sub items of ',
	unselectSubItemsOfParentTreeNode: 'Unselect sub items of ',
	andSubItemsOfParentTreeNode: 'and sub items',
	selectEntireTreeLabel: 'Select entire tree',
	unSelectEntireTreeLabel: 'Unselect entire tree',
	selectAsBranchTooltip: 'Select as branch',
	selectOptionsTooltip: 'Select options',
	itemsLabel: 'Items',
	selectedItemsAndTransferOptions: 'Selected items and merge strategies',
	parentItemsNotExistAlert:
		'Some parent items don’t exist in the destination and are necessary for the transfer.',
	resolveAutomaticallyLabel: 'Resolve automatically',
	yourSelectionLabel: 'Your selection',
	automaticallyAddedLabel: 'Automatically added',
	reviewAutomaticallyAdded:
		'Review the automatically added items and merge strategies',
};

const createTransferMergeStrategies = {
	overrideExistingItemMergeStrategyName: 'Override existing item',
	overrideExistingItemMergeStrategyDescription:
		" - Overwrites pre-existing items in the destination and creates items if they don't exist.",
	keepExistingItemMergeStrategyName: 'Keep existing item',
	keepExistingItemMergeStrategyDescription:
		" - The destination items remain unchanged and creates items if they don't exist.",
	latestWinMergeStrategyName: 'Latest win',
	latestWinMergeStrategyDescription:
		" - Keeps the latest items from source or destination and creates items if they don't exist.",
	overrideExistingTreeMergeStrategyName: 'Override existing tree',
	overrideExistingTreeMergeStrategyDescription:
		' - Overrides the entire destination tree and transfers only the selected items. (Completely deletes tree nodes and transfer new items)',
};

const allDeployments = {
	create: 'Create deployment',
	project: 'Project',
	projectId: 'Project ID',
	environment: 'Environment',
	environmentId: 'Environment ID',
	date: 'Date',
	status: 'Status',
	myDeployments: 'My deployments',
	recentDeployments: 'Recent deployments',
	notDeployed: 'Not deployed',
	explanation: 'Explanation of deployment statuses',
	alertText: 'There are {bold} and {bold2}',
	progress: ' - Deployment is in progress',
	complete: ' - Deployment is complete',

	queued: ' - Deployment will begin when in progress deployment completes',
	failed: ' - Deployment failed. Check your code and try again',
	skipped:
		" - A new deployment has been added to the queue. This deployment has been skipped and won't run.",
	learnMore: 'Learn more',
	detail: 'about deployment statuses',
	alertText3: (plural: boolean) => `There ${plural ? `are` : `is`} {bold}`,
	alertText4: (plural: boolean) => `There ${plural ? `are` : `is`} {bold2}`,
};

const createProject = {
	title: 'XM Cloud foundation template',
	subTitle:
		'Create a project and deploy and environment with the XM Cloud foundation template',
	mTitle: 'Deploy XM Cloud',
	mSubTitle: 'Deploy an XM Cloud authoring environment and front end',
};

const choosePath = {
	pageTitle: 'Create project',
	pageSubtitle: 'Choose the type of project you want to create:',
	badge1: 'Recommended',
	badge2: 'Advanced',
};

export const commonCw = {
	modalSubTitle1:
		'Create a project and deploy an environment with the XM Cloud foundation template',
	modalSubTitle2:
		'Create a project and deploy an environment with the front-end template',
	modalSubTitle3:
		'Create a project and deploy an environment with the standalone CM template',
	modalSubTitle4:
		'Create a project and deploy an environment with your own code',
	continue: 'Continue',
	learnMore: 'Learn more',
	createProject: 'Create project',
	setupRepo: 'Set up repository',
	authEnv: 'Configure authoring environment',
	editHost: 'Set up editing host',
	reviewFinish: 'Review and deploy',
	previous: 'Back',
	connect: 'Connect',
	choose: 'Choose',
	deselect: 'Unselect',
	envName: 'Environment name',
	startDeployment: 'Start deployment',
	retry: 'Retry',
	choosePathTitle: 'Source code',
	chooseSourceControls: 'Choose source control',
};

export const firstStep = {
	title: 'Name your project',
	title2: 'Name your projects',
	desc: 'This template deploys your authoring and front end together at the same time, in the same repository. We recommend this option even if you are migrating an existing site.',
	desc2: 'This option uses your customized XM Cloud foundation repository as the source for your project.',
	maxCharacters: 'Project name cannot be longer than 100 characters',
	desc3: 'The front-end template is a front-end-only source code repository template that includes a default Sitecore JSS/Next.JS site.',
	notSameProjectName: 'Project names can not be the same',
	notSameRepoName: 'Repository names can not be the same',
};

const secondStep = {
	title: 'Set up repository',
	desc: "Choose where to store your project's code.",
	quickSetup: 'Connect later',
	quickSetupDesc:
		'If you don’t have repo we can get you started quickly.  You can move your code to a repo later. You will have no source control in the mean time.',
	connectTo: 'Connect to a new account',
	connectGitHub: 'Connect to GitHub',
	connectAzure: 'Connect to Azure DevOps',
	github: 'GitHub',
	azure: 'Azure DevOps',
	existingConnection: 'Existing connection',
	newConnection: 'New connection',
	goBackBtnText: 'Back to source control options',
	info1: 'If you do not know your connection, please talk to your admin',
	info2: 'This repository will contain your project code. The repository name must follow the requirements of your source control system.',
	chooseAcccount: (a: string) => `Choose ${a} account`,
	nameYourRepo: 'Name your new repository',
	info4: 'This repository will contain your project code. The repository name must follow the requirements of your source control system.',
	account: 'Account',
	desc2: 'Select the source of your code.',
	title2: 'Your code source',
	errorMsg:
		'No repos in your account. You’ll have to go to {bold} and create one, then try again.',
	chooseOrg: 'Choose an organization',
	chooseProject: 'Choose a project',
	chooseRepository: 'Choose a repository',
	requiredAccount: 'An account is needed to continue',
	repoRequired: 'A repository name is needed to continue',
	repositoryNameValidationMessage:
		'Repository name must be 1-100 characters long and can only contain alphanumeric characters, underscores (_), periods (.), and hyphens (-).',
	orgRequired: 'A organization name is needed to continue',
	projectNameRequired: 'A project name is needed to continue',
	alreadyRepoExist: 'There is already a repository with this name',
	foundationTemplate: 'Foundation template',
	useOwnCode: 'Use your own code',
	usetemplate: 'Use XM Cloud template',
	sourceControl: 'Choose source control',
	selectedtxt: 'Selected',
};

const thirdStep = {
	title: 'Authoring environment',
	info1: 'Enter a meaningful name, for example a name including staging, dev, or prod.',
	info2: 'A production environment is optimized for production traffic and is covered by the Sitecore SaaS Service Level Agreement. This setting cannot be changed later.',
	info3: 'A push to your repository in your source control system automatically triggers a redeployment of the environment in the XM Cloud Deploy app.',
	prodEnv: 'Production SaaS SLA',
	autoDeploy: 'Auto deploy on push to repository',
	maxCharacters: 'Environment name cannot be longer than 100 characters',
	monoRepo: 'Monorepo',
	separateRepo: 'Separate repositories',
};

export const fourthStep = {
	fieldLabel1: 'Path to source code',
	desc1: 'The relative path from the xmcloud.build.json file to the app source code. Usually, it is the relative path from the root folder.',
	subTitle1: 'Setup the hosting of your front end',
	pathRequired: 'The relative path is needed to continue',
	availableEnvironments: 'Available to authoring environment',
	requriedCmEnv: 'A CM environment is needed to continue',
};

export const fifthStep = {
	title: 'Review and deploy',
	projectName: 'Project name',
	repo: 'Repository',
	authEnv: 'Authoring environment',
	desc1: 'This is the name of the project',
	sourceControlConnection: '{titleValue} connection',
	repoName: 'Repository name',
	envName: 'Environment name',
	prodEnv: 'Production SaaS SLA',
	autoDeploy: 'Auto deploy on push to repository',
	edit: 'Edit',
	repositoryText: 'Repository',
	chooseEnv: 'Authoring environment',
	info1: 'XM Cloud template repository is being imported to Azure DevOps repository. This may take a few minutes.',
	sourceControl: 'Source control',
	envtxt: 'Environment',
	adoOrgName: 'Azure DevOps organization name',
	adoProjectName: 'Azure DevOps project name',
	statusTitle: 'Project creation status',
	createGithubRepoInfo:
		'XM Cloud template repository is being imported to GitHub repository. It may take a while',
};

export const templateDescriptions = {
	combined:
		'This template deploys your authoring and front end together at the same time, in the same repository. The option also supports, if you have customized the XM Cloud foundation repository and want to use this as the source for your project',
	eh: 'The {bold} is a front-end-only source code repository template that includes a default Sitecore JSS/Next.JS site. We recommend this option when you are working primarily with front-end teams that will connect to an Authorig (Standalone CM) environment.)',
	cm: 'The {bold} is meant for use with a front-end-only project. Choose this option  to separate your front end from your authoring.',
	dual: 'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
};

export const createCombinedProject = {
	combined:
		'This template deploys your authoring and front end together at the same time, in the same repository. We recommend this option even if you are migrating an existing site.',
	owncode:
		'Choose this option if you have customized the XM Cloud foundation repository and want to use this as the source for your project.',
};

export const templateTitles = {
	dual: 'XM Cloud content service + Web app',
	eh: 'Web app',
	cm: 'XM Cloud content service',
	combined: 'Deploy an XM Cloud authoring environment and front end',
};

export const devSettings = {
	runCode: 'Run code',
	checkoutCode: 'Checkout code',
	download: 'Download .env.local',
	chooseSite: 'Site',
	preview: 'Preview',
	live: 'Live',
	ContextId: 'Context',
	title: 'Environment variables',
	info1: 'If using a multisite setup, choose the default site.',
	info2: 'Live grants access to published content. Preview grants access to all content, including drafts and unpublished content.',
	title1: 'Local development',
	subTitle1:
		'1. In your terminal, clone the environment repository to your local machine.',
	subTitle2:
		'2. Download and add the following .env.local file to your app folder.',
	subTitle3: '3. Install dependencies and run your app.',
	info: 'Information',
	txt1: 'The Developer settings provides the quickest way to connect your app to an XM Cloud site. ',
	txt2: 'Use the environment variables when deploying your app or to get started with local development. ',
	txt3: 'To use these environment variables, your app must have JSS v21.6.0 or later. If your app uses an earlier JSS version, use the environment variables in Sites > Settings >',
	txt4: 'To get started, select a {bold} and {bold2}.',
	noSite: 'No sites available',
	txt5: ' Developer settings',
	editingSecretNotFound:
		'Not available because the environment is not linked to a repository.',
	jssVersionTooltip:
		'You can find your app`s JSS version in the version property of the package.json file.',
	contextIdTooltip:
		'The context ID environment variable is used to new JSS versions(21.6+)',
	step1: '1. In your terminal, clone the environment repository to your local machine and install dependencies.',
	step2: '2. Copy the environment variables and paste them in your app`s .env.local or .env file.',
	step3: '3. Run the app.',
};

export const editProject = {
	githubRepo: 'GitHub repository',
	adoRepo: 'Azure DevOps repository',
	branch: 'Branch',
	env: 'Environment',
	deployOnCommit: 'Auto deploy on push to repository',
	adoProject: 'Azure DevOps project',
	adoOrg: 'Azure DevOps organization',
	goToConnection: 'Go to connection page',
	requiredProvider: 'A provider is needed to continue',
	accountChangeConfirmation:
		'Are you sure? This will remove the current repository connection.',
	branchChangeConfirmation:
		'Are you sure? Changing your repo or branch needs to be done very carefully for live sites. ',
	confirmation1: 'Yes, create a new {provider} connection',
	confirmation2: 'Yes, make the change ',
	error1: 'Account is not found. Please create a new connection.',
	error2: 'It appears your repository is empty. You cannot connected to an empty repository. Your repository should contain an XM Cloud codebase.',
	error3: 'Installation does not exist or broken. Please re-create the connection and try again.',
	error4: (repo = 'It') =>
		`${repo} is not found. Please select a valid repository.`,
	error5: 'An error occurred. Please try again later.',
	error6: 'The source control service is not availble',
	forbiddenChars:
		'Project name cannot contain the following characters: " + < > &',
	cannotEndWith: 'Project name cannot end with "?"',
};

export const systemHealthStatus = {
	errMsg1:
		'There is a problem with the system status. Please try again later.',
	errMsg2:
		'You cannot create a new project due to some of dependencies are not healthy. You can try again later.',
	btnText: 'Go to system status',
	githubStatus:
		'GitHub is degraded. You can not create a new project with GitHub. You can try again later.',
	adoStatus:
		'Azure DevOps  is degraded. You can not create a new project with Azure DevOps. You can try again later.',
	bothUnavailable:
		'Both providers are unavailable. You can not create a new project. You can try again later.',
	warn1: 'The {variableTxt} is degraded. It will automatically resume when the component is healthy.',
	warn2: 'The following components are degraded.\n {variableTxt}\n It will automatically resume when the components are healthy.',
	errMsg3:
		'You cannot {variableTxt2} due to the following components are degraded.\n {variableTxt}\n You can try again later.',
	errMsg4:
		'You cannot {variableTxt2} due to {variableTxt} is not healthy. You can try again later.',
	envEdit: 'edit the environment',
	envCreate: 'create a new environment',
	providerStatusCanNotSelected:
		'{provider} is not healthy. You can not select this provider. You can try again later.',
	noProviderAvailable:
		'All providers are unavailable at the moment. You can try again later.',
	noProvider: 'No provider found',
	configuration: 'Configuration',
	createVariable: 'create a new environment variable',
	editDeleteVariable: 'edit or delete the environment variable',
	errorMsg5:
		'Something went wrong in the source control connection, please check the connection.',
	errorMsg6: 'Service temporarily unavailable. Retry later.',
	errorMsg7:
		'GitHub is not healthy. It is not possible to retrieve a list of GitHub repositories.',
	errorMsg8:
		'Azure DevOps is not healthy. It is not possible to retrieve a list of Azure DevOps organizations, projects and repositories.',
	unableFetchAdoRepos:
		' It is not possible to retrieve a list of Azure DevOps organizations, projects and repositories.',
	unableFetchGithubRepos:
		' It is not possible to retrieve a list of GitHub repositories.',
	unavailableLogging:
		'Monitoring is not healthy. Logging may not be available.',
	gitopsError:
		'The Deploy API is unhealthy. Some app features will not work. The team is fixing the issue. If it persists, please check the {button} for updates.',
};

export {
	app,
	connections,
	environments,
	info,
	repository,
	projects,
	environment,
	deployments,
	settings,
	documentation,
	sites,
	confirmation,
	cmInstanceLog,
	deploymentLogs,
	environmentVariable,
	editCreateEnvironment,
	credentials,
	placeHolders,
	createDeployment,
	allDeployments,
	createProject,
	choosePath,
	secondStep,
	thirdStep,
	transfers,
	transferDetails,
	createTransfer,
	createTransferMergeStrategies,
};
