import { AzureFeatureFlagValue } from '../@xmcloud/hooks/context/azureFeatureFlags';

export const AzureFeatureFlagsMock: AzureFeatureFlagValue[] = [
	{
		id: 'TBR',
		description: 'TBR[FE]',
		enabled: true,
		conditions: { clientFilters: [] },
	},
	{
		id: 'DeveloperSettingForLowerJss',
		description: 'DeveloperSettingForLowerJss[FE]',
		enabled: true,
		conditions: { clientFilters: [] },
	},
	{
		id: 'CreateProjectV2',
		description: 'CreateProjectV2[FE]',
		enabled: true,
		conditions: { clientFilters: [] },
	},
	{
		id: 'RegenerateContextId',
		description: 'RegenerateContextId[FE]',
		enabled: true,
		conditions: { clientFilters: [] },
	},
];
