import { useCreateProject } from '../context/CreateProjectContext';
import { IRepositoryTemplateDetails } from '../../../app/models/repositoriesModel';
import {
	ProjectTypesEnum,
	repoForCM,
	repoForEH,
} from '../../../app/pages/create-project/helpers';
import { useXMCloudTemplateADOProjectCreation } from '..';

const { CM, EH } = ProjectTypesEnum;

export const useDualADOProjectCreation = ({
	XMCloudTemplate,
	token,
}: {
	token: string;
	XMCloudTemplate: IRepositoryTemplateDetails;
}) => {
	const { isMonoRepo, isOwnCode } = useCreateProject();
	const templateName = XMCloudTemplate?.name || '';

	const {
		onHandleCreationProcess: onCMHandleCreationProcess,
		repositoryCreationStatus: cmRepositoryCreationStatus,
		isLoading: isCMLoading,
		projectCreationStatus: cmProjectCreationStatus,
		environmentCreationStatus: cmEnvironmentCreationStatus,
		deploymentCreationStatus: cmDeploymentCreationStatus,
		shouldRetry: cmShouldRetry,
		repositoryDetails,
		currentEnvironmentId: cmCurrentEnvironmentId,
	} = useXMCloudTemplateADOProjectCreation({
		projectNameSpace: 'cmProjectname',
		repositoryNamespace: isMonoRepo ? 'repository' : 'cmRepository',
		repositoryIdNamespace: isMonoRepo ? 'repositoryId' : 'cmRepositoryId',
		repositoryBranchNamespace: isMonoRepo
			? 'repositoryBranch'
			: 'cmRepositoryBranch',
		environmentNamespace: 'cmEnvironmentName',
		deployOnCommitNamespace: 'cmDeployOnCommit',
		adoProjectNamespace: isMonoRepo ? 'adoProjectName' : 'cmAdoProjectName',
		adoOrganizationNamespace: isMonoRepo
			? 'adoOrganization'
			: 'cmAdoOrganization',
		XMCloudTemplate,
		projectType: CM,
		token,
		onCmProjectCreationSuccess,
		skipCreateRepository: isOwnCode ? true : false,
		repositoryRelativePathNamespace: isOwnCode
			? 'cmRepositoryRelativePath'
			: 'repositoryRelativePath',
		templateRepository: isMonoRepo ? templateName : repoForCM,
	});

	const repositoryBranch = repositoryDetails.repositoryBranch;

	const {
		onHandleCreationProcess: onEHHandleCreationProcess,
		repositoryCreationStatus: ehRepositoryCreationStatus,
		isLoading: isEHLoading,
		projectCreationStatus: ehProjectCreationStatus,
		environmentCreationStatus: ehEnvironmentCreationStatus,
		deploymentCreationStatus: ehDeploymentCreationStatus,
		shouldRetry: ehShouldRetry,
	} = useXMCloudTemplateADOProjectCreation({
		projectNameSpace: 'ehProjectname',
		repositoryNamespace: isMonoRepo ? 'repository' : 'ehRepository',
		repositoryIdNamespace: isMonoRepo ? 'repositoryId' : 'ehRepositoryId',
		repositoryBranchNamespace: isMonoRepo
			? 'repositoryBranch'
			: 'ehRepositoryBranch',
		environmentNamespace: 'ehEnvironmentName',
		deployOnCommitNamespace: 'ehDeployOnCommit',
		adoProjectNamespace: isMonoRepo ? 'adoProjectName' : 'ehAdoProjectName',
		adoOrganizationNamespace: isMonoRepo
			? 'adoOrganization'
			: 'ehAdoOrganization',
		XMCloudTemplate,
		projectType: EH,
		token,
		shouldGotoDeploymentsPage: true,
		skipCreateRepository: isMonoRepo || isOwnCode ? true : false,
		repositoryBranch,
		cmCurrentEnvironmentId,
		repositoryRelativePathNamespace: isOwnCode
			? 'ehRepositoryRelativePath'
			: 'repositoryRelativePath',
		templateRepository: isMonoRepo ? templateName : repoForEH,
	});

	function onCmProjectCreationSuccess(cmEnvId: string) {
		onEHHandleCreationProcess(cmEnvId);
	}

	function handleDualProjectCreation() {
		if (cmDeploymentCreationStatus === 'success') {
			onEHHandleCreationProcess();
			return;
		}
		onCMHandleCreationProcess();
	}

	const isLoading = isCMLoading || isEHLoading;
	const shouldRetry = cmShouldRetry || ehShouldRetry;
	return {
		isLoading,
		handleDualProjectCreation,
		cmRepositoryCreationStatus,
		cmProjectCreationStatus,
		cmEnvironmentCreationStatus,
		cmDeploymentCreationStatus,
		ehRepositoryCreationStatus,
		ehProjectCreationStatus,
		ehEnvironmentCreationStatus,
		ehDeploymentCreationStatus,
		shouldRetry,
	};
};
