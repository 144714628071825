import { AxiosResponse, AxiosError } from 'axios';
import { useToast } from '@chakra-ui/react';
import { useInfiniteQuery, useQueryClient } from 'react-query';
import {
	defaultErrorHandler,
	useAuthMutation,
	useAuthQuery,
} from '../apiUtils/queryHelper';
import { HttpStatusCodes } from '../apiUtils/resources';
import config from '../config/config';
import { isAxiosError } from '../../@xmcloud/utils/errorUtils';
import {
	ICreateDeploymentResponse,
	IGetDeploymentResponse,
	IPromoteDeploymentResponse,
} from '../models/deploymentModel';
import {
	EnvironmentPublishMode,
	IEdgeCredentials,
	IEnvironmentPayload,
	IEnvironmentPublishSitePayload,
	IEnvironmentPublishSiteResponse,
	IEnvironmentVariableDetails,
	IEnvironmentVariablePayload,
	IGetEnvironmentLogs,
	IGetEnvironmentPublishStatusResponse,
	IGetEnvironmentRenderingHostsAndSitesResponse,
	IGetEnvironmentResponse,
	IGetEnvironmentsLimitation,
	IGetPreviewApiKeyResponse,
	IGetRestartEnvironmentResponse,
	IGraphQLRequest,
	IRestartEnvironmentResponse,
	TPaginatedEnvironments,
} from '../models/environmentModel';
import { useFeature } from '../feature-flag/features';
import { useEnvironmentDetails } from '../../@xmcloud/hooks';
import { ProjectTypesEnum } from '../pages/create-project/helpers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAuthenticatedAxios } from '../apiUtils/AxiosProvider';
import _ from 'lodash';
import { sleep } from '../../@xmcloud/utils/promiseUtils';

const { COMBINED } = ProjectTypesEnum;

export const useUpdateEnvironmentMutation = ({ id }: { id: string }) => {
	const queryClient = useQueryClient();
	const { scope, url, queryKey } = config.environments.environment;
	return useAuthMutation(
		(axiosInstance) =>
			(environment: IEnvironmentPayload & { environmentId?: string }) => {
				const environmentId = environment.environmentId || id;
				let payload = {
					...environment,
				};
				payload = _.omit(payload, 'environmentId');

				return axiosInstance?.put<IEnvironmentPayload>(
					url(environmentId),
					payload,
				);
			},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(queryKey(id));
			},
		},
		scope,
	);
};

export const useDeleteEnvironmentMutation = () => {
	const queryClient = useQueryClient();
	const { scope, url, queryKey } = config.environments.environment;
	return useAuthMutation(
		(axiosInstance) =>
			({ id }: { id: string }) => {
				return axiosInstance?.delete(url(id));
			},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(queryKey(variables.id));
			},
		},
		scope,
	);
};

export const useGetEnvironment = ({
	id,
	enabled = true,
}: {
	id: string;
	enabled?: boolean;
}) => {
	const _enabled = !!enabled && !!id;
	const SplitEhAndCm = useFeature('SplitEhAndCm');
	const { scope, url: urlV1, queryKey } = config.environments.environment;
	const { url: urlV2 } = config.environments.environment_v2;
	const url = SplitEhAndCm ? urlV2 : urlV1;
	return useAuthQuery(
		queryKey(id),
		(axiosInstance) => {
			return axiosInstance?.get<IGetEnvironmentResponse>(url(id));
		},
		{ enabled: _enabled },
		scope,
	);
};

export const useGetEnvironmentLogs = ({
	environmentId,
	enabled,
	type,
}: {
	environmentId: string;
	enabled?: boolean;
	type?: string | null;
}) => {
	const { scope, url, queryKey } = config.environments.environment_logs;
	return useAuthQuery(
		queryKey(environmentId, type),
		(axiosInstance) => {
			return axiosInstance?.get<IGetEnvironmentLogs[]>(
				url(environmentId, type),
			);
		},
		{ enabled },
		scope,
	);
};

export const useGetEnvironmentLogTypes = ({
	environmentId,
	enabled,
	onNotFoundError,
}: {
	environmentId: string;
	enabled?: boolean;
	onNotFoundError?: ((err: unknown) => void) | undefined;
}) => {
	const { scope, url, queryKey } = config.environments.environment_log_types;
	const toast = useToast();
	return useAuthQuery(
		queryKey(environmentId),
		(axiosInstance) => {
			return axiosInstance?.get<string[]>(url(environmentId));
		},
		{
			enabled,
			onError: (err) => {
				if (
					isAxiosError(err) &&
					err.response &&
					err.response.status === HttpStatusCodes.NOT_FOUND
				) {
					onNotFoundError && onNotFoundError(err);
				} else {
					defaultErrorHandler(err, scope, toast);
				}
			},
		},
		scope,
	);
};

export const useGetEnvironmentLog = ({
	environmentId,
	logName,
	enabled,
	onDownloadProgress,
}: {
	environmentId: string;
	logName: string;
	enabled?: boolean;
	onDownloadProgress?: ((progressEvent: any) => void) | undefined;
}) => {
	const [logs, setlogs] = useState<string[]>([]);
	const [downloadUrl, setDownloadUrl] = useState('');
	const [isProgressStart, setIsProgressStart] = useState(false);
	const chunks = useRef<Blob[]>([]);

	const { scope, url, queryKey } = config.environments.environment_log_V2;

	const result = useAuthQuery(
		queryKey(environmentId, logName),
		(axiosInstance) => {
			return axiosInstance?.get<string>(url(environmentId, logName), {
				onDownloadProgress: onDownloadProgress,
				...{ responseType: 'blob' },
			});
		},
		{ enabled, cacheTime: 0 },
		scope,
	);

	useEffect(() => {
		if (!result.data) return;
		setIsProgressStart(true);
		const CHUNK_SIZE = 102400000;

		const blob = new Blob([result.data.data]);

		for (let i = 0; i < blob.size; i += CHUNK_SIZE) {
			const chunk = blob.slice(i, i + CHUNK_SIZE);
			chunks.current.push(chunk);
		}

		const reader = new FileReader();
		reader.onloadend = () => {
			const text = reader.result as string;
			const lines = text.split('\n');
			setIsProgressStart(false);
			setlogs(lines);
		};
		reader.onerror = (error) => {
			setlogs(['Error reading the file, please try again.']);
		};
		reader.readAsText(chunks.current[0]);

		const url = window.URL.createObjectURL(blob);

		setDownloadUrl(url);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [result.data]);

	const hanldeNextChucnk = useCallback((index: number) => {
		setIsProgressStart(true);

		const reader = new FileReader();
		reader.onloadend = async () => {
			await sleep(500);
			const text = reader.result as string;
			const lines = text.split('\n');
			setIsProgressStart(false);
			setlogs(lines);
		};
		reader.onerror = (error) => {
			setlogs(['Error reading the file, please try again.']);
		};
		reader.readAsText(chunks.current[index]);
	}, []);

	const totalPages = chunks.current.length;

	return {
		...result,
		logs,
		isProgressStart,
		downloadUrl,
		hanldeNextChucnk,
		totalPages,
	};
};

export const useGetEnvironmentLogSize = ({
	environmentId,
	logName,
	enabled,
}: {
	environmentId: string;
	logName: string;
	enabled?: boolean;
}) => {
	const { scope, url } = config.environments.environment_log;
	return useAuthQuery(
		['log-detail-head'],
		(axiosInstance) => {
			return axiosInstance?.head(url(environmentId, logName));
		},
		{ enabled, cacheTime: 0 },
		scope,
	);
};

export const useCreateEnvironmentDeploymentMutation = () => {
	const queryClient = useQueryClient();

	const { scope, url, queryKey } =
		config.environments.environment_deployments_v2;

	return useAuthMutation(
		(axiosInstance) =>
			({ environmentId }: { environmentId: string }) => {
				return axiosInstance?.post<
					void,
					AxiosResponse<ICreateDeploymentResponse>
				>(
					url(environmentId),
					undefined,
					// redeploy is always true when creating a deployment from Deploy UI App
					{ params: { redeploy: true } },
				);
			},
		{
			onSuccess: (_, values) => {
				queryClient.invalidateQueries(queryKey(values.environmentId));
			},
		},
		scope,
	);
};

export const useRestartEnvironmentMutation = (
	environmentId: string,
	onError?: (error: AxiosError) => void,
) => {
	const queryClient = useQueryClient();
	const { scope, url } = config.environments.environment_restart;

	return useAuthMutation(
		(axiosInstance) =>
			({ environmentId }: { environmentId: string }) => {
				return axiosInstance?.post<IRestartEnvironmentResponse>(
					url(environmentId),
					{
						environmentId,
					},
				);
			},
		{
			onSettled: (_, error) => {
				const e = error as AxiosError;
				error && onError && onError(e);
				queryClient.invalidateQueries(
					config.environments.environment_restart.queryKey(
						environmentId,
					),
				);
			},
		},
		scope,
	);
};

export const useGetEnvironmentRestartStatus = ({
	environmentId,
	enabled,
	refetchInterval,
	onSuccess,
}: {
	environmentId: string;
	enabled?: boolean;
	refetchInterval?: number;
	onSuccess?: (data: IGetRestartEnvironmentResponse) => void;
}) => {
	const { scope, url, queryKey } = config.environments.environment_restart;
	const _enabled = !!enabled && !!environmentId;

	return useAuthQuery(
		queryKey(environmentId),
		(axiosInstance) => {
			return axiosInstance?.get<IGetRestartEnvironmentResponse>(
				url(environmentId),
			);
		},
		{
			enabled: _enabled,
			refetchInterval,
			onSuccess: (data) => {
				onSuccess && onSuccess(data.data);
			},
		},
		scope,
	);
};

export const useGetEnvironmentDeploymentList = ({
	environmentId,
	projectId,
	refetchInterval,
	refetchIntervalInBackground,
}: {
	environmentId: string;
	projectId: string;
	refetchInterval?: number;
	refetchIntervalInBackground?: boolean;
}) => {
	const queryClient = useQueryClient();
	const toast = useToast();

	const { scope, url, queryKey } =
		config.environments.environment_deployments_v2;

	return useAuthQuery(
		queryKey(environmentId),
		(axiosInstance) => {
			return axiosInstance?.get<IGetDeploymentResponse[]>(
				url(environmentId),
			);
		},
		{
			refetchInterval,
			refetchIntervalInBackground,

			onError: (err: unknown) => {
				if (
					isAxiosError(err) &&
					err.response &&
					err.response.status === HttpStatusCodes.NOT_FOUND
				) {
					queryClient.invalidateQueries(
						config.projects.project_environments.queryKey(
							projectId,
						),
					);
				} else {
					defaultErrorHandler(err, scope, toast);
				}
			},
		},
		scope,
	);
};

export const usePromoteDeploymentMutation = () => {
	const queryClient = useQueryClient();
	const environmentDeploymentPromoteQuery =
		config.environments.environment_deployment_promote_v2;

	const environmentDeploymentQuery =
		config.environments.environment_deployments_v2;

	return useAuthMutation(
		(axiosInstance) =>
			({
				environmentId,
				deploymentId,
			}: {
				environmentId: string;
				deploymentId: string;
			}) => {
				return axiosInstance?.post<IPromoteDeploymentResponse>(
					environmentDeploymentPromoteQuery.url(
						environmentId,
						deploymentId,
					),
				);
			},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(
					environmentDeploymentQuery.queryKey(
						variables.environmentId,
					),
				);
				queryClient.invalidateQueries(
					config.environments.environment.queryKey(
						variables.environmentId,
					),
				);
			},
		},
		environmentDeploymentPromoteQuery.scope,
	);
};

export const useGetEnvironmentVariables = ({
	environmentId,
	enabled,
}: {
	environmentId: string;
	enabled?: boolean;
}) => {
	const { scope, url, queryKey } = config.environments.environment_variables;
	return useAuthQuery(
		queryKey(environmentId),
		(axiosInstance) => {
			return axiosInstance?.get<IEnvironmentVariableDetails[]>(
				url(environmentId),
			);
		},
		{ enabled },
		scope,
	);
};

export const useUpsertEnvironmentVariableMutation = () => {
	return useAuthMutation(
		(axiosInstance) =>
			({
				environmentId,
				variableName,
				data,
			}: {
				environmentId: string;
				variableName: string;
				data: IEnvironmentVariablePayload;
			}) => {
				return axiosInstance?.post<IEnvironmentVariablePayload>(
					config.environments.environment_variable_upsert.url(
						environmentId,
						variableName,
					),
					data,
				);
			},
		{},
		config.environments.environment_variable_upsert.scope,
	);
};

export const useDeleteEnvironmentVariableMutation = () => {
	return useAuthMutation(
		(axiosInstance) =>
			({
				environmentId,
				variableName,
				target,
			}: {
				environmentId: string;
				variableName: string;
				target: string | undefined;
			}) => {
				const url =
					config.environments.environment_variable_delete_by_target.url(
						environmentId,
						variableName,
						target ?? '',
					);

				return axiosInstance?.delete(url);
			},
		{},
		config.environments.environment_variable_delete_by_target.scope,
	);
};

export const useGetAllEnvironmentsLimitation = (enabled = true) => {
	const { scope, url, queryKey } =
		config.environments.environment_all_limitation;
	return useAuthQuery(
		queryKey,
		(axiosInstance) => {
			return axiosInstance?.get<IGetEnvironmentsLimitation>(url);
		},
		{ enabled },
		scope,
	);
};

export const useGetEnvironmentRenderingHostsAndSites = ({
	host,
	enabled,
	onError,
	refetchInterval = 0,
	refetchOnWindowFocus = false,
}: {
	host: string | undefined;
	enabled?: boolean;
	onError?: ((err: unknown) => void) | undefined;
	refetchInterval?: number;
	refetchOnWindowFocus?: boolean;
}) => {
	const _enabled = !!enabled && !!host;
	const { scope, url, queryKey } = config.environments.environment_authoring;
	return useAuthQuery(
		queryKey('rendering-hosts-and-sites', host),
		(axiosInstance) => {
			return axiosInstance?.post<
				IGraphQLRequest,
				AxiosResponse<IGetEnvironmentRenderingHostsAndSitesResponse>
			>(url(host), {
				operationName: 'GetRenderingHostsAndSites',
				variables: {},
				query: `query GetRenderingHostsAndSites {
					languages {
					  edges {
						node {
						  name
						}
					  }
					}
					renderingHosts: item(where: {path: "/sitecore/system/Settings/Services/Rendering Hosts"}) {
					  name
					  itemId
					  path
					  template {
						templateId
						name
					  }
					  children {
						nodes {
						  name
						  itemId
						  path
						  template {
							templateId
							name
						  }
						  url: field(name: "ServerSideRenderingEngineApplicationUrl") {
							name
							value
						  }
						}
					  }
					}
					solutionSites(input: {database: "master"}) {
					  name
					  id
					  posMappings {
						name
						language
					  }
					}
					sitesToRenderingHosts: sites(includeSystemSites: false) {
					  site: rootItem {
						name
						itemId
						path
						template {
						  templateId
						  name
						}
						children {
						  nodes {
							name
							itemId
							path
							template {
							  templateId
							  name
							}
							children(includeTemplateIDs: ["{8357F958-9AAA-46DB-8898-36448A96356F}"]) {
							  nodes {
								name
								itemId
								path
								template {
								  templateId
								  name
								}
								children {
								  nodes {
									name
									itemId
									path
									template {
									  templateId
									  name
									}
									renderingHost: field(name: "RenderingHost") {
									  name
									  value
									}
								  }
								}
							  }
							}
						  }
						}
					  }
					}
				  }
				  `,
			});
		},
		{
			enabled: _enabled,
			onError(err) {
				typeof onError !== 'undefined' && onError(err);
			},
			refetchInterval,
			refetchOnWindowFocus,
		},
		scope,
	);
};

export const useEnvironmentPublishSiteMutation = ({
	host,
	onSuccess,
}: {
	host: string;
	onSuccess?: () => void;
}) => {
	const queryClient = useQueryClient();
	const { scope, url, queryKey } = config.environments.environment_authoring;
	return useAuthMutation(
		(axiosInstance) =>
			(environmentPublish: IEnvironmentPublishSitePayload) => {
				return axiosInstance?.post<
					IGraphQLRequest,
					AxiosResponse<IEnvironmentPublishSiteResponse>
				>(url(host), {
					operationName: 'EnvironmentPublishSiteMutation',
					variables: {
						languages: environmentPublish.languages,
						publishSiteMode:
							EnvironmentPublishMode[
								environmentPublish.publishSiteMode
							],
					},
					query: `mutation EnvironmentPublishSiteMutation($languages: [String!]!, $publishSiteMode: PublishSiteMode!) {
					publishSite(input: {languages: $languages, publishSiteMode: $publishSiteMode, targetDatabases: ["experienceedge"]}) {
					  operationId
					}
				  }`,
				});
			},
		{
			onSuccess: () => {
				queryClient.invalidateQueries(queryKey('publish-status', host));
				typeof onSuccess !== 'undefined' && onSuccess();
			},
		},
		scope,
	);
};

export const useGetEnvironmentPublishStatus = ({
	host,
	operationId,
	enabled,
	refetchInterval = 0,
	onSuccess,
}: {
	host: string;
	operationId: string;
	enabled?: boolean;
	refetchInterval?: number;
	onSuccess?: (d: unknown) => void;
}) => {
	const { url, scope, queryKey } = config.environments.environment_authoring;
	return useAuthQuery(
		queryKey('publish-status', host),
		(axiosInstance) => {
			return axiosInstance?.post<
				IGraphQLRequest,
				AxiosResponse<IGetEnvironmentPublishStatusResponse>
			>(url(host), {
				operationName: 'EnvironmentPublishStatus',
				variables: {
					publishingOperationId: operationId,
				},
				query: `query EnvironmentPublishStatus($publishingOperationId: String!) {
					publishingStatus(publishingOperationId: $publishingOperationId) {
					  isDone
					  isFailed
					  processed
					  state
					}
				  }`,
			});
		},
		{
			enabled,
			refetchInterval: refetchInterval,
			onSuccess: (data) => {
				typeof onSuccess !== 'undefined' && onSuccess(data);
			},
		},
		scope,
	);
};

export const useGetEdgeToken = ({
	environmentId,
	enabled,
}: {
	environmentId: string;
	enabled?: boolean;
}) => {
	const { scope, url, queryKey } = config.environments.environment_edge_token;
	return useAuthQuery(
		queryKey(environmentId),
		(axiosInstance) => {
			return axiosInstance?.get<IEdgeCredentials>(url(environmentId));
		},
		{ enabled },
		scope,
	);
};

export const useGetEditingSecret = ({
	environmentId,
	enabled = true,
	onNotFoundError,
}: {
	environmentId: string;
	enabled?: boolean;
	onNotFoundError?: ((err: unknown) => void) | undefined;
}) => {
	const toast = useToast();
	const _enabled = !!enabled && !!environmentId;
	const { scope, url, queryKey } =
		config.environments.environment_editing_secret;
	return useAuthQuery(
		queryKey(environmentId),
		(axiosInstance) => {
			return axiosInstance?.get<string>(url(environmentId));
		},
		{
			enabled: _enabled,
			onError: (err) => {
				if (
					isAxiosError(err) &&
					onNotFoundError &&
					err.response &&
					err.response.status === HttpStatusCodes.NOT_FOUND
				) {
					onNotFoundError(err);
				} else {
					defaultErrorHandler(err, scope, toast);
				}
			},
		},
		scope,
	);
};

export const useGetPreviewApiToken = ({
	environmentId,
	enabled,
}: {
	environmentId: string;
	enabled?: boolean;
}) => {
	const { url, scope, queryKey } = config.environments.environment_authoring;

	const { environment } = useEnvironmentDetails(environmentId);
	const host = environment?.host;
	const _enabled = !!enabled && !!environmentId && !!host;

	return useAuthQuery(
		queryKey('items', environmentId + 'preview'),
		(axiosInstance) => {
			return axiosInstance?.post<
				IGraphQLRequest,
				AxiosResponse<IGetPreviewApiKeyResponse>
			>(url(host), {
				variables: {},
				query: `
					query {
						item(where:{path:"/sitecore/system/Settings/Services/API Keys/xmcloudpreview"})
						 {itemId}
					} `,
			});
		},
		{ enabled: _enabled },
		scope,
	);
};

export const useGetEnvironmentsByQuery = ({
	onSuccess,
	_enabled = true,
}: {
	onSuccess?: (lastPage: any) => void;
	_enabled?: boolean;
}) => {
	const axiosContext = useAuthenticatedAxios();
	const { url, queryKey } =
		config.environments.environments_queryBy_projectType;
	const enabled = _enabled;
	const PAGE_SIZE = 49;

	const result = useInfiniteQuery({
		queryKey: queryKey(COMBINED),
		queryFn: ({ pageParam = 1 }) =>
			axiosContext
				?.get<TPaginatedEnvironments>(url(pageParam, PAGE_SIZE))
				.then((res) => res.data),
		getNextPageParam: (lastPage, allPages) =>
			lastPage.data.length < lastPage.pageSize
				? undefined
				: allPages.length + 1,
		onSuccess: (data) => {
			const pages = data.pages || [];
			const lastPage = pages[pages.length - 1]?.data.length || [];
			onSuccess && onSuccess(lastPage);
		},
		enabled,
	});

	const data = useMemo(() => {
		const pages = result.data?.pages || [];
		return pages.map((r) => r.data).flat();
	}, [result.data?.pages]);

	return { ...result, data };
};

export const useRegenerateContextIdMutation = () => {
	const queryClient = useQueryClient();
	const { regenerate_contextid, environment } = config.environments;
	const { url, scope } = regenerate_contextid;

	return useAuthMutation(
		(axiosInstance) =>
			({ environmentId }: { environmentId: string }) => {
				return axiosInstance?.post<IGetEnvironmentResponse>(
					url(environmentId),
				);
			},
		{
			onSuccess: (data, variables) => {
				queryClient.invalidateQueries(
					environment.queryKey(variables.environmentId),
				);
			},
		},
		scope,
	);
};
