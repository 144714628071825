import { useMemo, useState } from 'react';
import { edgeUrl } from '../../../../config/envConfig';
import { useGetTenant } from '../../../../services/platformInventory';
import { CodeBlock } from '../../../../shared-components';
import { useGetEdgeToken } from '../../../../services/environments';
import { useParams } from 'react-router-dom';

const EDGE_IDE_URL = `${edgeUrl}/api/graphql/v1`;

export const CombinedLowerJssSettings: React.FC<{
	siteName: string;
	cdpEmbeddedTenantID: string;
	sites: any[];
}> = ({ siteName, cdpEmbeddedTenantID, sites }) => {
	const { environmentId } = useParams<{
		environmentId: string;
	}>();
	const [token, setToken] = useState('');
	const { data, isLoading } = useGetTenant({
		tenantId: cdpEmbeddedTenantID,
	});

	const { refetch, isFetching } = useGetEdgeToken({
		environmentId: environmentId!,
		enabled: false,
	});
	const apiURL = data?.data?.annotations?.apiURL || '';
	const cdpClientKey =
		data?.data?.annotations?.['TenantClaims.cdp_client_key'] || '';

	const cdpPos = useMemo(() => {
		const site = sites.find((s) => s.name === siteName);
		if (!site) return '';

		const record: Record<string, string> = {};
		for (const pos of site.posMappings) {
			record[pos.language] = pos.name;
		}

		return JSON.stringify(record);
	}, [siteName, sites]);

	const envVariablesLines = [
		`NEXT_PUBLIC_CDP_TARGET_URL=${apiURL}`,
		`NEXT_PUBLIC_CDP_CLIENT_KEY=${cdpClientKey}`,
		`JSS_APP_NAME=${siteName}`,
		`NEXT_PUBLIC_CDP_POINTOFSALE=${cdpPos}`,
		`GRAPH_QL_ENDPOINT=${EDGE_IDE_URL}`,
	];

	const clipboardTextList = [
		...envVariablesLines,
		`SITECORE_API_KEY=${token}`,
	];

	return (
		<CodeBlock
			items={envVariablesLines}
			clipboardText={clipboardTextList.join('\n')}
			data-testid="code-block"
			key="environment-code-block"
			isLoading={isLoading}
			minH="48"
			showButtonItem={true}
			buttonItemKey="SITECORE_API_KEY="
			buttonItemValue={token}
			isItemLoading={isFetching}
			onItemClick={() => {
				refetch().then((res) => {
					setToken(res?.data?.data?.apiKey || '');
				});
			}}
		/>
	);
};
